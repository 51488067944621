import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './../../styles/generalStyles';
import { BoxGeneral, BoxBorder } from './../../styles/boxesGeneral'
import { Text, Title4 } from './../../styles/textGeneral'
import { UnlistPaymentMetods } from './styles/sMainPayment'
import MainModal from "./../modal/MainModal";
import MetodPlinYape from './../general/cards/MetodPlinYape'
import MainCulqi from './MainCulqi';
import { Culqi } from 'react-culqi';
import { useFirestore } from 'reactfire';
import { AppContext} from '../../App'
import useMounted from '../../hooks/useMounted';

const paymentMetodsList = [
  {
    metod: 'Yape',
    image: require('./../../images/paymentMetods/image-28.png')
  },
  {
    metod: 'Plin',
    image: require('./../../images/paymentMetods/image-29.png')
  },
  {
    metod: 'Tarjeta',
    image: require('./../../images/paymentMetods/image-30.png')
  },
]

export default function MainPayment() {
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const db = useFirestore()
  const mounted = useMounted()
  const { dataOfUser, currentUser, packageInfo, setPackageInfo } = useContext(AppContext)
  const location = useLocation();
  const totalPrice = location.state?.dataOfService?.totalPrice ?? 0;
  const dataOfService = location.state?.dataOfService

  const closeModal = () => setModalIsOpen(false);
  const [modalName, setModalName] = useState(null);
  return (
    <main className='base'>
      <Wrapper home>
        <BoxGeneral>
          <Title4>Selecciona un método de pago</Title4>
          <Text>Pago a realizar: <span style={{fontWeight: 'bold'}}>S/ {location.state?.dataOfService?.totalPrice ?? 'Ocurrió un error en el pago, recargue la página y vuelva a realizar el envío'}</span></Text>
          <UnlistPaymentMetods>
            {
              paymentMetodsList.map((metod) =>
                metod.metod === 'Tarjeta' ?
                <MainCulqi 
                  key={metod.metod} 
                  totalPrice={totalPrice}
                  metod={metod.metod}
                  dataOfService={dataOfService}
                  >
                  <Culqi>
                    {({ openCulqi }) => {
                        return (
                          <div>
                            <li onClick={openCulqi}>
                              <BoxBorder as="button">
                                <Text>{metod.metod}</Text>
                                <img src={metod.image} alt={metod.metod} />
                              </BoxBorder>
                            </li>
                          </div>
                        );
                    }}
                  </Culqi>
                </MainCulqi> : 
                <li key={metod.metod} onClick={() => {
                  setModalIsOpen(true);
                  setModalName(metod.metod);
                }}>
                  <BoxBorder as="button">
                    <Text>{metod.metod}</Text>
                    <img src={metod.image} alt={metod.metod} />
                  </BoxBorder>
                </li>
              )
            }
          </UnlistPaymentMetods>
        </BoxGeneral>
      </Wrapper>
      
      <MainModal
          isOpen={modalIsOpen}
          closeModal={closeModal}
          isClose={modalIsOpen}
          background="#F8FAFD"
          width="auto"
          height="auto"
          borderRadius="8px"
          fillColor="#262A2E"
        >
          <MetodPlinYape
            paddingModal="30px 25px"
            totalPrice={totalPrice}
            metod={modalName}
            dataOfUser={dataOfUser}
            currentUser={currentUser}
            dataOfService={dataOfService}
            db={db}
            closeModal={closeModal}
            mounted={mounted}
            packageInfo={packageInfo}
            setPackageInfo={setPackageInfo}
          /> 
        </MainModal>
    </main>
  )
}
