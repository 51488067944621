import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../App";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Wrapper } from "./../../styles/generalStyles";
import { BoxGeneral, Table } from "./../../styles/boxesGeneral";
import { Text, Title4 } from "./../../styles/textGeneral";
import { Button } from "./../../styles/buttonGeneral";
import { useFirestore } from "reactfire";
import ConfirmUploadData from "../general/cards/ConfirmUploadData"
import Spinner from "../spinner/MainSpinner";

import RecoverResumeReceptors from './algorithms/RecoverResumeReceptors'

//Import algorithms
import { capitalize } from "../../algorithms/general/capitalize";

export default function ResumeReceptorsB2COnHold({amountValue}) {    
    const [t] = useTranslation("main");
    const db = useFirestore();
    const navigate = useNavigate()
    const { currentUser, dataOfUser } = useContext(AppContext);

    if(!amountValue) return null
    
    const [receptorData, error, loading] = RecoverResumeReceptors(db, currentUser, amountValue)
    
    if (loading) return <Spinner />
    if (error) return null

    return (
        <main className="base">
            <div>
                <BoxGeneral table>
                    <div>
                        <Title4>{t("resumenReceptors.tabResumenReseptorsTitle")}</Title4>
                    </div>
                    <div className="tableContent">
                        <Table>
                            <thead>
                                <tr className="tableHead">
                                    <th>{t("table.quantity")}</th>
                                    <th>{t("table.receptor")}</th>
                                    <th>{t("table.district")}</th>
                                    <th>{t("table.province")}</th>
                                    <th>{t("table.department")}</th>
                                    <th>{t("table.weight")}</th>
                                    <th>{t("table.total")}</th>
                                </tr>
                                {(currentUser && receptorData && (dataOfUser?.typeOfUser === "B2C") ) &&
                                    receptorData.map((receptor, index) => {
                                      return (
                                        <tr key={receptor.senderCode.concat(index + 1)}>
                                            <th>{receptor.numberOfPackets || (receptorData.length)}</th>
                                            <th>{receptor?.name ? capitalize(receptor.name) : 
                                            (receptor?.ecommerceName ? capitalize(receptor.ecommerceName) : 'Sin Nombre')}</th>
                                            <th>{receptor?.desc_ubigeo_inei ? capitalize(receptor.desc_ubigeo_inei) : '-'}</th>
                                            <th>{receptor?.desc_prov_inei ? capitalize(receptor.desc_prov_inei) : '-'}</th>
                                            <th>{receptor?.desc_dep_inei ? capitalize(receptor.desc_dep_inei) : '-'}</th>
                                            <th>{receptor?.weight ? capitalize(receptor.weight) : '-'}</th>
                                            <th>{receptor?.valueOfpacket ? capitalize(receptor.valueOfpacket) : '-'}</th>
                                        </tr>
                                      );
                                  })
                                } 
                            </thead>
                        </Table>
                    </div>
                </BoxGeneral>
                <BoxGeneral oneButton buttonsTable>
                    <Button
                        primary
                        large
                        onClick={() => {
                          navigate('/new-package/payment', {status: {paymentRequest: true}})
                        }}
                    >
                        {t("packet.nextButton")}
                    </Button>
                </BoxGeneral>
            </div>
        </main>
    );
}
