import styled, { css } from 'styled-components';

export const RecentShipmentContainer = styled.div`
  ${props => props.withButton && css`
    button {
      margin: 10px 0 0 0;
    }
  `}
  
  @media(min-width:1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${props => props.withButton && css`
      button {
        margin: 0;
        width: 150px;
      }
    `}
  }
`

export const BoxStatusContent = styled.div`
  padding: 5px 10px;
  width: fit-content;
  margin: 10px 0 0 0;
  cursor: default;

  p {
    font-weight: ${props => props.theme.weight.medium};
    font-size: 0.750em;
  }

  ${props => props.red && css`
    color: ${props => props.theme.red500Color};
    background: ${props => props.theme.red000Color};
  `}
  
  ${props => props.yellow && css`
    background: #FEF6E9;
    color: #774C05;
  `}

  ${props => props.green && css`
    color: #DCF8D8;
    background: ${props => props.theme.red000Color};
  `}

  ${props => props.blue && css`
    background: #E9F1FE;
    color: #0C3475;
  `}

  @media(min-width:1200px) {
    margin: 0;
  }
`;

export const RecentShipmentDetails = styled.div`
  h4 {
    font-size: 0.875em;
    font-weight: ${props => props.theme.weight.medium};
    margin: 0 0 10px 0;
  }

  .recentShipmentDetail {
    display: flex;
    font-size: 0.875em;
    margin: 0 0 7px 0;

    p {
      font-weight: ${props => props.theme.weight.light};
    }

    span {
      font-weight: ${props => props.theme.weight.medium};
    }
  }

  @media(min-width:1200px) {
    .recentShipmentDetail {
      margin: 0 30px 0 0;
    }
    
    .recentShipmentDetailsContent {
      display: flex;
    }

    .tripleRowD {
      display: flex;
      flex-direction: column;
    }

    .tripleRowContent {
      display: flex;
      margin: 0 0 15px 0;

      &:last-child {
        margin: 0;
      }
    }
  }
`
