import React from 'react'
import MainHelpCenter from './../components/helpCenter/MainHelpCenter'
import HeaderTwo from './../components/general/header/HeaderTwo'
import FooterTwo from './../components/general/footer/FooterTwo'

export default function HelpCenter() {
  return (
    <>
      <HeaderTwo button="Regresar al inicio" service="Centro de asistencia" />
      <MainHelpCenter />
      <FooterTwo />
    </>
  )
}
