//list of colors
export const colors = {
	color: {
		black: '#262A2E',
		background: '#F8FAFD',
		base: '#E9EBEF',
		brand: {
			gray: '#818286',
			classicBlue: '#174F82',
			lightBlue: '#47A9F0',
			ceruleanBlue: '#088AC0',
			white: '#FFFFFF',
		},
		gray: {
			gray100: '#EBEBEB',
			gray200: '#BFC0C2',
			gray400: '#B6B8C3',
			gray500: '#58626B',
		},
		blue: {
			blue000: '#E8EFF5',
			blue100: '#EEF3F9',
			blue200: '#E0E5EA',
			blue300: '#E6F1FB',
			blue500: '#ADC8E1',
		},
		red: {
			red000: '#FDEEEE',
			red500: '#E53E3E',
		}
	},
	shadow: {
		card: '0px 0px 8px rgba(38, 42, 46, 0.25)',
	}
}

//List of fonts and weights
export const fonts = {
	brandFont: 'Montserrat',
	secondaryFont: 'Roboto',
	weight: {
		thin: 100,
		extraLight: 200,
		light: 300,
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
		extraBold: 800,
		black: 900,
	},
}