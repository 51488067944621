import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../App";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Wrapper } from "./../../styles/generalStyles";
import { BoxGeneral, Table } from "./../../styles/boxesGeneral";
import { Text, Title4 } from "./../../styles/textGeneral";
import { Button } from "./../../styles/buttonGeneral";
import { useFirestore } from "reactfire";
import MainModal from "../modal/MainModal";
import UploadDataStatus from "../general/cards/UploadDataStatus"
import Spinner from "../spinner/MainSpinner";

import SupportNoticeCoverage from '../general/cards/SupportNoticeCoverage'

//Import algorithms
import { capitalize } from "../../algorithms/general/capitalize";

export default function ResumeReceptorsB2C({setComplete, setDataPackaging}) {    
    const [t] = useTranslation("main");
    const db = useFirestore();
    const navigate = useNavigate()
    const [loading, setLoading] = useState()
    const { setDataOfService, dataOfService, currentUser, dataOfUser, setPackageInfo, packageInfo, localUniqueReceptorData } = useContext(AppContext);
    const [sendedStatus, setSendedStatus] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalCoverageIsOpen, setModalCoverageIsOpen] = useState(false);
    const [confirmCoverage, setConfirmCoverage] = useState(null);
    
    const closeModal = () => {
        if (sendedStatus !== null) {
            if (sendedStatus) {
                setModalIsOpen(false);
                setComplete(false);
                navigate(`/history`, {replace: true})
            } else {
                setModalIsOpen(false);
            }
        }
      };

    function closeModalCoverage (e) {
        if(confirmCoverage !== null) {
          if(confirmCoverage) {
            setModalCoverageIsOpen(false)
            setConfirmCoverage(false)
          }
        } else {
          setModalCoverageIsOpen(false)
        }
    }   

    return (
        <main className="base">
            <div>
                <BoxGeneral table>
                    <div>
                        <Title4>{t("resumenReceptors.tabResumenReseptorsTitle")}</Title4>
                    </div>
                    <div className="tableContent">
                        <Table>
                            <thead>
                                <tr className="tableHead">
                                    <th>{t("table.quantity")}</th>
                                    <th>{t("table.receptor")}</th>
                                    <th>{t("table.district")}</th>
                                    <th>{t("table.province")}</th>
                                    <th>{t("table.department")}</th>
                                    <th>{t("table.weight")}</th>
                                    <th>{dataOfService?.totalPrice ? t("table.totalPrice") : t("table.total")}</th>
                                </tr>
                                {(currentUser && dataOfService?.packetAndReceptorData && (dataOfUser?.typeOfUser === "B2C") )  &&
                                    dataOfService.packetAndReceptorData.map((receptor, index) => {
                                        return (
                                            <tr key={receptor.name.concat(index + 1)}>
                                                <th>{receptor.numberOfPackets || dataOfService.packetAndReceptorData.length}</th>
                                                <th>{receptor?.name ? capitalize(receptor.name) : 
                                                (receptor?.ecommerceName ? capitalize(receptor.ecommerceName) : 'Sin Nombre')}</th>
                                                <th>{receptor.ubigeo?.desc_ubigeo_inei ? capitalize(receptor.ubigeo.desc_ubigeo_inei) : '-'}</th>
                                                <th>{receptor.ubigeo?.desc_prov_inei ? capitalize(receptor.ubigeo.desc_prov_inei) : '-'}</th>
                                                <th>{receptor.ubigeo?.desc_dep_inei ? capitalize(receptor.ubigeo.desc_dep_inei) : '-'}</th>
                                                <th>{receptor?.weight ? capitalize(receptor.weight) : '-'}</th>
                                                <th>{receptor?.ratePrice ? `S/ ${receptor.ratePrice}` : receptor.value}</th>
                                            </tr>
                                        );
                                    })
                                } 
                                { dataOfService?.totalPrice ?

                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>{`S/ ${dataOfService?.totalPrice}`}</th>
                                    </tr> :
                                    '-'
                                }
                            </thead>
                        </Table>
                    </div>
                </BoxGeneral>
                <BoxGeneral oneButton buttonsTable>
                    {!packageInfo.finished ?
                        <Button
                            primary
                            large
                            onClick={() => {
                                dataOfService.serviceType === "province" ?
                                setModalCoverageIsOpen(true) :
                                (
                                    packageInfo?.paymentRequest ?
                                    navigate('/history') :
                                    navigate('/new-package/payment', {state: {paymentRequest: true, dataOfService, localUniqueReceptorData}})
                                )
                            }}
                        >
                            {t("packet.nextButton")}
                        </Button> :
                       null
                    }
                </BoxGeneral>
            </div>

            <MainModal
                isOpen={modalCoverageIsOpen}
                closeModal={closeModalCoverage}
                isClose={closeModalCoverage}
                background="#F8FAFD"
                width="auto"
                hegiht="auto"
                borderRadius="8px"
                fillColor="#262A2E"
            >
                <SupportNoticeCoverage
                    paddingModal="30px 60px"
                    closeModalCoverage={closeModalCoverage}
                    setConfirmCoverage={setConfirmCoverage}
                    dataOfService={dataOfService}
                    db={db}
                    currentUser={currentUser}
                    setLoading={setLoading}
                    setSendedStatus={setSendedStatus}
                    setDataPackaging={setDataPackaging}
                    dataOfUser={dataOfUser}
                    setPackageInfo={setPackageInfo}
                    setModalCoverageIsOpen={setModalCoverageIsOpen}
                />
            </MainModal>

            {(sendedStatus !== null) && confirmCoverage && (
                <MainModal
                    isOpen={true}
                    closeModal={closeModal}
                    isClose={modalIsOpen}
                    background="#F8FAFD"
                    width="auto"
                    height="auto"
                    borderRadius="8px"
                    fillColor="#262A2E"
                >
                <UploadDataStatus
                    paddingModal="30px 60px"
                    sendedStatus={sendedStatus}
                    closeModal={closeModal}
                    dataOfService={dataOfService}
                    db={db}
                    currentUser={currentUser}
                    setLoading={setLoading}
                    setSendedStatus={setSendedStatus}
                    setDataOfService={setDataOfService}
                    setComplete={setComplete}
                    setDataPackaging={setDataPackaging}
                    dataOfUser={dataOfUser}
                />
                </MainModal>
            )}
        </main>
    );
}
