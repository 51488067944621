import { writeBatch, doc } from "firebase/firestore";

export const changePayState = async (db, localPayState, remito, setRemito) => {
  try {
    const batch = writeBatch(db);
    const localRemito = remito.recovered;
    localRemito.payState = localPayState;
    if (!remito.remitoId) {
      return false;
    }

    batch.set(
      doc(db, "senders", remito.remitoId),
      {
        payState: localPayState,
      },
      { merge: true }
    );

    remito.recovered.listOfReferences.map((ref) => {
      return batch.set(
        doc(db, "receptors", ref.id),
        {
          payState: localPayState,
        },
        { merge: true }
      );
    });

    await batch
      .commit()
      .then(() => {
        console.log("perfect change");
        setRemito({ recovered: localRemito });
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err.message);
  }
};
