import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../App";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../styles/generalStyles";
import { BoxGeneral, Table } from "../../styles/boxesGeneral";
import { Text, Title4 } from "../../styles/textGeneral";
import { Button } from "../../styles/buttonGeneral";
import { ReactComponent as ArrowSVG } from "./../../icons/arrow-left.svg";
import { useFirestore } from "reactfire";
import MainModal from "../modal/MainModal";
import UploadDataStatus from "../general/cards/UploadDataStatus";
import ConfirmUploadData from "../general/cards/ConfirmUploadData"
import Spinner from "../spinner/MainSpinner";

//Import algorithms
import { capitalize } from "../../algorithms/general/capitalize";
import { sendDataToDB } from "./algorithms/sendDataToDB";

import RecoverResumeReceptors from './algorithms/RecoverResumeReceptors'

export default function ResumeReceptorsB2B({amountValue}) {    
    const [t] = useTranslation("main");
    const db = useFirestore();
    const navigate = useNavigate()
    const { currentUser, dataOfUser } = useContext(AppContext);
    
    if(!amountValue) return null
    
    const [receptorData, error, loading] = RecoverResumeReceptors(db, currentUser, amountValue)
    
    if (loading) return <Spinner />
    if (error) return null

    return (
        <main className="base">
            <div>
                <BoxGeneral table>
                    <div>
                        <Title4>{t("resumenReceptors.tabResumenReseptorsTitle")}</Title4>
                    </div>
                    <div className="tableContent">
                        <Table>
                            <thead>
                                <tr className="tableHead">
                                    <th>{t("table.quantity")}</th>
                                    <th>{t("table.receptor")}</th>
                                    <th>{t("table.district")}</th>
                                    <th>{t("table.province")}</th>
                                    <th>{t("table.department")}</th>
                                    <th>{t("table.weight")}</th>
                                    <th>{t("table.total")}</th>
                                </tr>
                                {(receptorData.length > 0 && dataOfUser?.typeOfUser === "B2B" ) &&
                                    receptorData.map((receptor, index) => {
                                        return (
                                            <tr key={receptor?.name.concat(index + 1)}>
                                                <th>{receptor.numberOfPackets || (receptorData.length)}</th>
                                                <th>{receptor?.name ? capitalize(receptor.name) : 
                                                (receptor?.ecommerceName ? capitalize(receptor.ecommerceName) : 'Sin Nombre')}</th>
                                                <th>{receptor?.desc_ubigeo_inei ? capitalize(receptor.desc_ubigeo_inei) : '-'}</th>
                                                <th>{receptor?.desc_prov_inei ? capitalize(receptor.desc_prov_inei) : '-'}</th>
                                                <th>{receptor?.desc_dep_inei ? capitalize(receptor.desc_dep_inei) : '-'}</th>
                                                <th>{receptor?.weight ? capitalize(receptor.weight) : '-'}</th>
                                                <th>{receptor?.valueOfpacket ? capitalize(receptor.valueOfpacket) : '-'}</th>
                                            </tr>
                                        );
                                    })
                                } 
                            </thead>
                        </Table>
                    </div>
                </BoxGeneral>
                <BoxGeneral onlyButtons buttonsTable>
                    <Button
                        primary
                        large
                        onClick={(e) => {
                            navigate('/history')
                        }}
                    >
                        {t("packet.nextButton")}
                    </Button>
                </BoxGeneral>
            </div>
        </main>
    );
}
