import React, { useEffect, useContext } from "react";
import { AppContext } from "../../App";
import CoverPage from "./CoverPage";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { Title2, Text, Title3, Title4 } from "./../../styles/textGeneral";
import { MainLandingSectionContainer } from "./styles/sMainLanding";
import { Wrapper } from "./../../styles/generalStyles";
import SolutionCard from "./SolutionCard";
import { ReactComponent as NextDayDelivery } from "./../../images/landing/envios-next-day.svg";
import { ReactComponent as SameDayDelivery } from "./../../images/landing/envios-same-day.svg";
import { ReactComponent as ProvinceDelivery } from "./../../images/landing/envios-provincia.svg";
import { ReactComponent as WebIntegration } from "./../../images/landing/pair-programming.svg";
import { ReactComponent as Wave3 } from "./../../images/waves/wave-3.svg";
import { Button } from "./../../styles/buttonGeneral";
import PackagingCard from "./PackagingCard";
import ImagePackagingCard from "./ImagePackagingCard";
import { ReactComponent as HistoryCheckSVG } from "./../../icons/history-check.svg";
import { ReactComponent as DeliveryCarSVG } from "./../../icons/delivery-car.svg";
import { ReactComponent as CreditCardSVG } from "./../../icons/credit-card.svg";
import { ReactComponent as PackageSVG } from "./../../icons/package-2.svg";
import { ReactComponent as MessageSVG } from "./../../icons/message.svg";
import ManagementCard from "./ManagementCard";
import BusinessClientCard from "./BusinessClientCard";
import { useNavigate } from "react-router-dom";

const coverPagesImagesList = [
  {
    image: require("./../../images/image-21.webp"),
    alt: "Envio de paquetes",
  },
  {
    image: require("./../../images/2.jpg"),
    alt: "Seguridad",
  },
];

export default function MainLanding() {
  const [t] = useTranslation("main");
  const navigate = useNavigate();
  const { currentUser } = useContext(AppContext);

  useEffect(() => {
    currentUser && navigate("/home");
  }, [currentUser]);

  const mailTo = (e) => {
    e.preventDefault()
    window.location.href = "mailto:ventas@serviciopuntual.com.pe?Subject=Quiero%20más%20información%20de%20Servicio%20Puntual"
  }
  
  const solutionCardsList = [
    {
      image: <NextDayDelivery />,
      title: `${t("solutions.nextDay")}`,
      text: `${t("solutions.nextDayDescription")}`,
    },
    {
      image: <SameDayDelivery />,
      title: `${t("solutions.sameDay")}`,
      text: `${t("solutions.sameDayDescription")}`,
    },
    {
      image: <ProvinceDelivery />,
      title: `${t("solutions.province")}`,
      text: `${t("solutions.provinceDescription")}`,
    },
    {
      image: <WebIntegration />,
      title: `${t("solutions.integration")}`,
      text: `${t("solutions.integrationDescription")}`,
    },
  ];

  const imagePackagingCardsList = [
    {
      image: require("./../../images/image-14.webp"),
      alt: "Imagen que hace referencia a confianza",
      title: `${t("landing.main.trust")}`,
      text: `${t("landing.main.trustDescription")}`,
    },
    {
      image: require("./../../images/image-5.webp"),
      alt: "Imagen que hace referencia a la seguridad",
      title: `${t("landing.main.securityTitle")}`,
      text: `${t("landing.main.securityDescription")}`,
    },
    {
      image: require("./../../images/image-6.webp"),
      alt: "Imagen que hace referencia a cobertura y efectividad",
      title: `${t("landing.main.coverage")}`,
      text: `${t("landing.main.coverageDescription")}`,
    },
  ];

  const postalCourierCardsList = [
    {
      color: "#47A9F0",
      background: "#47a9f01c",
      svg: <MessageSVG />,
      title: 'Distribución masiva',
      text: 'Reparto de documentos transaccionales, publicaciones, comunicaciones de entidades estales, privadas entre otros.',
    },
    {
      color: "#47A9F0",
      background: "#47a9f01c",
      svg: <PackageSVG />,
      title: 'Habilitado Postal',
      text: 'Servicio de picking, packing, ordenamiento, ensobrado, embolsado, etiquetado, engrampado de cargo y control de calidad.',
    }
  ];

  const courierDistributionCardsList = [
    {
      color: "#088AC0",
      background: "#088ac01c",
      svg: <DeliveryCarSVG />,
      title: 'Distribución Regular',
      text: 'Entrega de paquetes y sobres con cobertura a nivel nacional.',
    },
    {
      color: "#088AC0",
      background: "#088ac01c",
      svg: <CreditCardSVG />,
      title: 'Distribución Valorados',
      text: 'Distribución pensada para documentos con valor legal, pólizas de seguro, tarjetas de crédito, SOATS, entre otros.',
    }
  ];

  const businessClientsList = [
    {
      image: require("./../../images/businessClients/business-1.png"),
      name: "enotria",
    },
    {
      image: require("./../../images/businessClients/business-2.png"),
      name: "mibanco",
    },
    {
      image: require("./../../images/businessClients/BBVA.png"),
      name: "bbva",
    },
    {
      image: require("./../../images/businessClients/business-4.png"),
      name: "bcp",
    },
    {
      image: require("./../../images/businessClients/claro-logo.png"),
      name: "claro",
    },
    {
      image: require("./../../images/businessClients/banco-pichincha.png"),
      name: "bancoPichincha",
    },
    {
      image: require("./../../images/businessClients/ripley-banco.png"),
      name: "bancoRipley",
    },
    {
      image: require("./../../images/businessClients/santander.png"),
      name: "santander",
    },
    {
      image: require("./../../images/businessClients/entel.png"),
      name: "entel",
    },
    {
      image: require("./../../images/businessClients/mapfre.png"),
      name: "mapfre",
    },
    {
      image: require("./../../images/businessClients/profuturo.jpg"),
      name: "profuturo",
    },
  ];

  return (
    <main>
      <CoverPage images={coverPagesImagesList} />
      <MainLandingSectionContainer lightBlue>
        <Wrapper>
          <Title2>
            <strong>Soluciones para tu empresa</strong>
          </Title2>
          <Text className="textLanding">
            <em>Gana tiempo</em>. Dedica esfuerzos a tu estrategia de venta, <br />
            Brand y producción.
          </Text>
          <Title3>Logística para E- Commerce</Title3>
          <Text className="subSubTitle">Modalidades de Entrega</Text>
          <ul>
            {solutionCardsList.map((card) => (
              <SolutionCard key={card.title} {...card} />
            ))}
          </ul>
          <Text className="doYouNeedHelpLanding">
            ¿Quieres dar el siguiente paso en la distribución de tu E- Commerce?
          </Text>
          <Link to="#" onClick={mailTo}>
            <Button white medium>
              {t("landing.main.writeUs")}
            </Button>
          </Link>
          <Wave3 className="wave" />
        </Wrapper>
      </MainLandingSectionContainer>
      <Wrapper>
        <MainLandingSectionContainer id="my-hash">
          <Title2 classicBlue small>
            <em><mark>Distribución Courier</mark> y servicios postales</em>
          </Title2>
          <ul className="packagingCardUnlist">
            <PackagingCard />
          </ul>
          <ul className="imagePackagingCardsUnlist">
            {imagePackagingCardsList.map((card) => (
              <ImagePackagingCard key={card.alt} {...card} />
            ))}
          </ul>
        </MainLandingSectionContainer>
        <MainLandingSectionContainer>
          <div>
            <Title2 classicBlue>Mensajería Postal</Title2>
            <ul className="managemmentCardsUnlist">
              {postalCourierCardsList.map((card) => (
                <ManagementCard key={card.color} {...card} />
              ))}
            </ul>
          </div>
          <div>
            <Title2 classicBlue>Distribución courier</Title2>
            <ul className="managemmentCardsUnlist secondPartMCL">
              {courierDistributionCardsList.map((card) => (
                <ManagementCard key={card.color} {...card} />
              ))}
            </ul>
          </div>
          {/* <Link to="/register">
            <Button primary medium>
              {t("landing.main.hireService")}
            </Button>
          </Link> */}
        </MainLandingSectionContainer>
        <MainLandingSectionContainer>
          <Title2 classicBlue>Confían en nosotros</Title2>
          <Text className="textLanding">
            {t("landing.main.ourCustomersDescription")}
          </Text>
          <ul className="businessClientCardsUnlist">
            {businessClientsList.map((card) => (
              <BusinessClientCard key={card.name} {...card} />
            ))}
          </ul>
        </MainLandingSectionContainer>
      </Wrapper>
    </main>
  );
}
