import React, { useContext } from "react";
import { AppContext } from "../../../App";
import { NavbarHomeItemContainer } from "./styles/sNavbarHomeItems";
import { NavLink, useNavigate } from "react-router-dom";
import { closeSession } from "../../../algorithms/closeSession";

export default function NavbarHomeItem({ icon, text, link, closeModal}) {
  const navigate = useNavigate();
  const { currentUser, setLocalUniqueReceptorData, setDataOfService, setPackageInfo } = useContext(AppContext)

  return (
    <li onClick={() => text === "Salir de la cuenta"? closeSession(navigate, setDataOfService, setLocalUniqueReceptorData, setPackageInfo): closeModal()}>
      <NavLink
        exact="true"
        to={(currentUser && (text === "Inicio"))? "/home" : link}
        className={({ isActive }) => (isActive ? "navbarMenuActive" : "")}
      >
        <NavbarHomeItemContainer>
          {icon}
          <p>{text}</p>
        </NavbarHomeItemContainer>
      </NavLink>
    </li>
  );
}
