import styled from 'styled-components';

export const DeliveredDetailsCardContainer = styled.div`
  img {
    width: 150px;
    height: 150px;
    border-radius: 4px;
    margin: 10px 0 0 0;
  }
`
