import React, { useState } from "react";
import { ModalWrapper } from "./../../../styles/generalStyles";
import { InputContainer, FormContainer } from "./../../../styles/inputGeneral";
import { Button } from "./../../../styles/buttonGeneral";
import { ErrorText } from "../../../styles/textGeneral";
import { TrackingPackageCardContainer } from "./styles/sTrackingPackageCard";
import { Navigate } from "react-router-dom";

//Import ALGORITHMS
import { recoverIdTracked } from "./algorithms/recoverIdTracked";

//Import firebase utils
import { useFirestore } from "reactfire";

export default function TrakingPackageCard({ width, paddingModal }) {
  const [trackingId, setTrackingId] = useState(null);
  const [trackedId, setTrackedId] = useState(null);
  const db = useFirestore();

  return (
    <ModalWrapper paddingModal={paddingModal}>
      <TrackingPackageCardContainer width={width}>
        <p>Seguimiento de envíos</p>
        <FormContainer>
          <InputContainer>
            <label>Número de tracking</label>
            <input
              type="text"
              onChange={(e) => {
                e.preventDefault();
                setTrackingId(e.target.value);
              }}
            />
          </InputContainer>
          <Button
            primary
            large
            onClick={async (e) => {
              e.preventDefault();
              recoverIdTracked(db, trackingId, setTrackedId);
            }}
          >
            Realizar seguimiento
          </Button>
          {trackedId && trackedId.status !== "NO ASIGNADO" && (
            <Navigate to="/tracking" replace={true} state={trackedId} />
          )}
        </FormContainer>
      </TrackingPackageCardContainer>
      {trackedId === false ||
        (trackedId?.status === "NO ASIGNADO" && (
          <ErrorText>Identificador no encontrado.</ErrorText>
        ))}
    </ModalWrapper>
  );
}
