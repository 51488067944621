export const verifyPackageDimensionLimit = (receptorData, dataOfUser) => {
  if(!receptorData && !dataOfUser && !(dataOfUser?.typeOfUser === "B2C")) {
    return null
  }
  const isInsideLimit = (item) => item.height <= 60 && item.width <= 40 && item.large <= 25
  const satisfiedPackagesDimension =  receptorData.every(isInsideLimit)

  return satisfiedPackagesDimension
}

export const verifyPackageWeightLimit = (receptorData, dataOfUser) => {
  if(!receptorData && !dataOfUser && !(dataOfUser?.typeOfUser === "B2C")) {
    return null
  }
  const isInsideLimitWeight = (item) => item.weight <= 6
  const satisfiedPackagesWeight =  receptorData.every(isInsideLimitWeight)

  return satisfiedPackagesWeight
}


export const verifyPackageLimitAll = (receptorData, dataOfUser, dataOfService, setNeedSupport, mounted) => {
  if(!dataOfUser && !(dataOfService.packetAndReceptorData.length > 0)) {
    return null
  }
  const weightPackage = receptorData && verifyPackageDimensionLimit(receptorData, dataOfUser)
  const dimensionPackage = receptorData && verifyPackageWeightLimit(receptorData, dataOfUser)
  if(
    weightPackage && 
    dimensionPackage && 
    (dataOfUser?.typeOfUser === "B2C") && 
    (dataOfService.serviceType === "same-day" || 
    dataOfService.serviceType === "next-day")
  ) {
    setNeedSupport(false)
    return false
  }
  setNeedSupport(true)
  return true
}