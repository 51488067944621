import React from 'react'
import MainTerms from './../components/helpCenter/MainTerms'
import HeaderTwo from './../components/general/header/HeaderTwo'
import FooterTwo from './../components/general/footer/FooterTwo'

export default function Terms() {
  return (
    <>
      <HeaderTwo button="Regresar al inicio" service="Centro de asistencia" />
      <MainTerms />
      <FooterTwo />
    </>
  )
}
