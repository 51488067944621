import React from 'react'
import { BoxGeneral } from './../../styles/boxesGeneral'
import { TrackingTimelineCardContainer } from './styles/sTrackingTimelineCard'

export default function TrackingTimelineCard() {
  return (
    <BoxGeneral shadow>
      <TrackingTimelineCardContainer>
        <div className='timelineGraphic'>
          <div className='timelineContent'>
            <div className='circleTimeline'></div>
            <div className='circleTimeline'></div>
            <div className='circleTimeline'></div>
            {/* <div className='circleTimeline'></div> */}
          </div>
          <div className='timelineBar'></div>
        </div>
        <div className='timelineDescription'>
          <div /* className='deliveredTimelineDescription' */>
            <p>Entregado <span>(con sello)</span></p>
            {/* <span className='dateDeliveredTimeline'>22/09/2020</span> */}
          </div>
          <p>En tránsito</p>
          <p>Asignado para recojo</p>
        </div>
      </TrackingTimelineCardContainer>
    </BoxGeneral>
  )
}
