import React from 'react'
import { BoxGeneral } from '../../styles/boxesGeneral'
import { Title4, Text } from "./../../styles/textGeneral";
import { Link } from 'react-router-dom'
import { DetailsTrackingCardContainer } from './styles/sDetailsTrackingCard'
import { NoDeliveredCardContainer } from './styles/sNoDeliveredCard'

export default function NoDeliveredCard() {
  return (
    <BoxGeneral shadow>
      <Title4>Estado del envío</Title4>
      <NoDeliveredCardContainer>
        <h3>No distribuido</h3>
        <DetailsTrackingCardContainer>
          <p>Motivo: <span>Retención</span></p>
        </DetailsTrackingCardContainer>
        <Text className='noDeliveredDescription'>Para más información revise nuestros <Link to="/">Términos de uso</Link> del servicio o contácte a un asistente.</Text>
        <Link to="/" className='contactSupport'>Contáctar con un asistente</Link>
      </NoDeliveredCardContainer>
    </BoxGeneral>
  )
}
