import React, { useState, useEffect, createContext, useReducer } from "react";
import { useReducedState } from "./hooks/useReducedState";
/*General Styled*/
import GlobalStyles from "./theme/GlobalStyles";
import { ThemeProvider } from "styled-components";
import theme from "./theme/Theme";
import InHomeLayout from "./components/layouts/InHomeLayout";
import MainSpinner from "./components/spinner/MainSpinner";

/* General Route */
import { Routes, Route, useNavigate } from "react-router-dom";

/* Pages */
import Landing from "./pages/Landing";
import Error404 from "./pages/Error404";
import Tracking from "./pages/Tracking";
import HelpCenter from "./pages/HelpCenter";
import Terms from "./pages/Terms";
import Assistance from "./pages/Assistance";
import Login from "./pages/Login";
import Register from "./pages/Register";
import EmailVerify from "./pages/EmailVerify";
import Home from "./pages/Home";
import Administrator from "./pages/Administrator";
import Profile from "./pages/Profile";
import NewPackage from "./pages/NewPackage";
import Payment from "./pages/Payment";
import ShippingHistory from "./pages/ShippingHistory";
import TrackingForHome from "./pages/TrackingForHome";
import RecoverPassword from "./pages/RecoverPassword";
import RecoverPasswordCM from "./pages/RecoverPasswordCM";
import ResumeReceptorsB2B from "./components/newPackage/ResumeReceptorsB2B";

import { ProtectedPayRoute } from "./algorithms/ProtectedPayRoute";
import { ProtectedTrackingRoute } from "./algorithms/ProtectedTrackingRoute";

//Import firebase utils
import {
  FirestoreProvider,
  useFirestoreDocData,
  useFirestore,
  useFirebaseApp,
} from "reactfire";
import {
  doc,
  collection,
  setDoc,
  getFirestore,
  initializeFirestore,
  enableIndexedDbPersistence,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

//import ALGORITHMS
import { recoverDataOfUser } from "./algorithms/recoverDataOfUser";

const AppContext = createContext();
const { Provider: AppProvider, Consumer } = AppContext;

function App() {
  const navigate = useNavigate();
  const firestoreInstance = getFirestore(useFirebaseApp());
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  const [dataOfUser, setDataOfUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataOfService, setDataOfService] = useReducedState({
    serviceType: null,
    amount: null,
    senderData: null,
    packetAndReceptorData: null,
  });
  const [localUniqueReceptorData, setLocalUniqueReceptorData] = useReducedState(
    {
      address: null,
      documentId: null,
      documentType: null,
      email: null,
      height: null,
      large: null,
      name: null,
      packetDescription: null,
      phone: null,
      reference: null,
      statesOfUbigeo: null,
      ubigeo: null,
      fragile: false,
      value: null,
      weight: null,
      width: null,
    }
  );
  const [packageInfo, setPackageInfo] = useReducedState({
    existData: null,
    amount: null,
    finished: false,
    paymentRequest: false
  });

  useEffect(() => {
    !currentUser &&
      onAuthStateChanged(auth, async (user) => {
        user ? setCurrentUser(user) : setCurrentUser(null);
        if (user) {
          !user.emailVerified && navigate("/email-verify");
          const recoverUser = await recoverDataOfUser(firestoreInstance, user);
          recoverUser.forEach((doc) => {
            setDataOfUser(doc.data());
          });
        }
        setLoading(false);
      });
  }, [auth, currentUser, firestoreInstance]);

  const appValue = {
    setCurrentUser,
    currentUser,
    dataOfUser,
    setDataOfService,
    dataOfService,
    setLocalUniqueReceptorData,
    localUniqueReceptorData,
    setPackageInfo,
    packageInfo,
  };

  if (loading) {
    return <MainSpinner />;
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AppProvider value={appValue}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Routes>
            {currentUser && (
              <Route
                path={"/home"}
                element={<InHomeLayout children={<Home />} />}
              />
            )}
            <Route path={"/"} element={<Landing />} />
            {dataOfUser &&
              dataOfUser?.typeOfUser ===
                "5114e09e-a181-11ec-b909-0242ac120002" && (
                <Route
                  path="/admin"
                  element={<InHomeLayout children={<Administrator />} />}
                />
              )}
            <Route
              path="/profile"
              element={<InHomeLayout children={<Profile />} />}
            />
            {dataOfUser &&
              dataOfUser?.typeOfUser !==
                "5114e09e-a181-11ec-b909-0242ac120002" && (
                <Route
                  path="/new-package/:service"
                  element={<InHomeLayout children={<NewPackage />} />}
                />
              )}

            <Route
              path="/resume"
              element={<InHomeLayout children={<ResumeReceptorsB2B />} />}
            />

            <Route
              path="/new-package/payment"
              element={
                <ProtectedPayRoute>
                  <InHomeLayout children={<Payment />} />
                </ProtectedPayRoute>
              }
            />
            {dataOfUser &&
              dataOfUser?.typeOfUser !==
                "5114e09e-a181-11ec-b909-0242ac120002" && (
                <Route
                  path="/history"
                  element={<InHomeLayout children={<ShippingHistory />} />}
                />
              )}
            <Route
              path="/tracking-home"
              element={<InHomeLayout children={<TrackingForHome />} />}
            />
            <Route
              path="/tracking"
              element={
                <ProtectedTrackingRoute currentUser={currentUser}>
                  <Tracking />
                </ProtectedTrackingRoute>
              }
            />
            <Route path="/help-center" element={<HelpCenter />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/assistance" element={<Assistance />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/email-verify" element={<EmailVerify />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route path="/recover-password-2" element={<RecoverPasswordCM />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </ThemeProvider>
      </AppProvider>
    </FirestoreProvider>
  );
}

export { App, Consumer as AppConsumer, AppContext };
