import React, { useState, useEffect } from "react";
import { Text } from "../../../styles/textGeneral";
import { ModalWrapper } from "../../../styles/generalStyles";
import { Button } from "../../../styles/buttonGeneral";
import { InputSelect } from "../../../styles/inputGeneral";

//Import firebase utils
import { useFirestore } from "reactfire";

//Import ALGORITHMS
import { changePayState } from "./algorithms/changePayState";

export default function ModifyPayState({
  paddingModal,
  closeModal,
  remito,
  setRemito,
}) {
  const db = useFirestore();
  const [localPayState, setLocalPayState] = useState(null);

  const payStates = [
    "EN ESPERA",
    "POR PAGAR",
    "PAGADO",
    "CONFIRMADO",
    "RECHAZADO",
  ];

  useEffect(() => {}, [remito]);

  if(remito.recovered && remito.recovered.typeOfUser === "B2B"){
    payStates.shift()
  }

  return (
    <ModalWrapper paddingModal={paddingModal}>
      <h2>Modificar estado de pago</h2>
      <Text center>Actualiza el estado de pago del bloque de pedidos</Text>
      <InputSelect>
        <label>Seleccionar Estado</label>
        <select
          value={remito.payState}
          onChange={(e) => {
            setLocalPayState(e.target.selectedOptions[0].id);
          }}
        >
          {payStates.map((ps) => {
            if(remito.recovered.payState === ps){
              return (
                <option selected={true} value={ps} key={ps} id={ps}>
                  {ps}
                </option>
              );
            }
            return (
              <option value={ps} key={ps} id={ps}>
                {ps}
              </option>
            );
          })}
        </select>
      </InputSelect>
      <Button
        primary
        medium
        onClick={async (e) => {
          e.preventDefault();
          await changePayState(db, localPayState, remito, setRemito);
          closeModal();
        }}
      >
        Cambiar estado
      </Button>
    </ModalWrapper>
  );
}
