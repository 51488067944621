import styled from 'styled-components';

export const TrackingTimelineCardContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;

  .timelineGraphic {
    position: relative;
  }

  .timelineContent {
    height: 170px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    place-items: center;
    margin: 0 10px 0 0;
  }

  .circleTimeline {
    width: 10px;
    height: 10px;
    background: ${props => props.theme.gray500Color};
    border-radius: 50px;
    position: relative;
    z-index: 10;
  }

  .timelineBar {
    width: 4px;
    height: 115px;
    background: ${props => props.theme.baseColor};
    position: absolute;
    top: 27px;
    left: 18px;
  }

  .timelineDescription {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    font-size: 0.875em;
    font-weight: ${props => props.theme.weight.semiBold};
    color: ${props => props.theme.gray500Color};
  }

  .deliveredTimelineDescription {
    color: ${props => props.theme.brandClassicBlueColor};
    position: relative;
  }

  .dateDeliveredTimeline {
    position: absolute;
    font-size: 0.750em;
    color: ${props => props.theme.gray500Color};
    margin: 4px 0 0 0;
    font-weight: ${props => props.theme.weight.medium};
  }

  /*Cuando se valida*/

  /*
  .circleTimeline {
    background: ${props => props.theme.brandClassicBlueColor};
  }

  .timelineDescription {
    color: ${props => props.theme.blackColor};
  }

  .timelineDescription {
    color: ${props => props.theme.blackColor};
  }
  */

  /*Cuando no se envía*/

  /*
  .timelineDescription {
    color: ${props => props.theme.red500Color};
  }
  */

  @media(min-width:768px) {
    .timelineContent {
      height: 200px;
    }

    .timelineDescription {
      font-size: 0.938em;
    }

    .timelineContent {
      margin: 0 20px 0 0;
    }

    .timelineBar {
      height: 144px;
      left: 13px;
    }
  }
`
