import React, { useEffect, useContext, useState } from "react";
import { useReducedState } from "../../hooks/useReducedState";
import { AppContext } from "../../App";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./../../styles/generalStyles";
import { ReactComponent as ClockSVG } from "./../../icons/clock.svg";
import { ReactComponent as DeliveryCardVG } from "./../../icons/delivery-car.svg";
import { ReactComponent as MapSVG } from "./../../icons/map.svg";
import { useFirestore } from "reactfire";
import SolutionCard from "./SolutionCard";
import OptionsSolution from "./OptionsSolution";
import OPSender from "./OPSender";
import OPReceptor from "./OPReceptor";
import OPContent from "./OPContent";
import MPUpload from "./MPUpload";
import TabResumeReceptors from "./TabResumeReceptors";
import { MainNewPackageContainer } from "./styles/sMainNewPackage";
import ResumeReceptorsB2B from "./ResumeReceptorsB2B";
import ResumeReceptorsB2C from "../newPackage/ResumeReceptorsB2C";
import RecoverResumeReceptors from './algorithms/RecoverResumeReceptors'
import ResumeReceptorsB2COnHold from "./ResumeReceptorsB2COnHold";
import Spinner from "../spinner/MainSpinner";
import useMounted from '../../hooks/useMounted'

export default function MainNewPackage() {
  const [t] = useTranslation("main");
  const [searchParams] = useSearchParams()
  const mounted = useMounted()
  const navigate = useNavigate();
  const db = useFirestore();
  const { setDataOfService, dataOfService, localUniqueReceptorData, currentUser, dataOfUser, packageInfo } =
  useContext(AppContext);
  const [complete, setComplete] = useState(false);
  const [needSupport, setNeedSupport] = useState(false);
  const [dataPackaging, setDataPackaging] = useReducedState({
    solutionCard: true,
    amount: window.innerWidth <= 1200 ? false : true,
    sender: false,
    receptor: false,
    content: false,
    upload: false,
  });
  const amountReceptor = searchParams.has("amount") ? parseInt(searchParams.get("amount")) : null
  const amountValue = (packageInfo.amount === amountReceptor) && amountReceptor
  useEffect(() => {
    const serviceType = localStorage.getItem("serviceType");
    !serviceType
    ? (() => {
      localStorage.setItem("serviceType", "next-day");
      navigate(`/new-package/next-day`, { replace: true });
      setDataOfService({ serviceType: "next-day" });
    })()
    : setDataOfService({ serviceType: serviceType });
  }, [dataPackaging, packageInfo]);


  const servicesList = [
    {
      title: `${t("solutions.nextDay")} (Lima)`,
      description: `${t("solutions.nextDayCondensed")}`,
      image: <ClockSVG />,
      route: "next-day",
    },
    {
      title: `${t("solutions.sameDay")} (Lima)`,
      description: `${t("solutions.sameDayCondensed")}`,
      image: <DeliveryCardVG />,
      route: "same-day",
    },
    {
      title: `${t("solutions.province")}`,
      description: `${t("solutions.pronvinceCondensed")}`,
      image: <MapSVG />,
      route: "province",
    },
  ];
  
  let basePackage

  if(packageInfo?.existData && 
    (dataOfUser?.typeOfUser === "B2C" 
    && !(dataOfService.serviceType === "province"))) {

    basePackage = <ResumeReceptorsB2C />
  
  } else if (searchParams.has("amount") && 
    (packageInfo.amount === amountReceptor) && 
    !(dataOfUser?.typeOfUser === "B2C")) 
  {

    basePackage = <ResumeReceptorsB2B 
                    amountValue={amountValue}
                  />
  
  } else if (packageInfo?.existData && 
    (dataOfUser?.typeOfUser === "B2C") && 
    dataOfService.serviceType === "province") {
      
      basePackage = <ResumeReceptorsB2C 
                      setComplete={setComplete}
                      setDataPackaging={setDataPackaging}
                    />
  
  } else if ((dataOfUser?.typeOfUser === "B2C") && 
    (dataOfService.serviceType === "same-day" || 
    dataOfService.serviceType === "next-day") &&
    searchParams.has("amount") && 
    (packageInfo.amount === amountReceptor)
  ){
    basePackage = <ResumeReceptorsB2COnHold 
                    amountValue={amountValue} 
                  />
  
  } else {
    basePackage = !complete ? (
      <MainNewPackageContainer>
        {(dataPackaging.solutionCard || window.innerWidth >= 1200) && (
          <ul>
            {servicesList.map((solution) => (
              <SolutionCard
                setDataPackaging={setDataPackaging}
                mounted={mounted}
                key={solution.title}
                {...solution}
              />
            ))}
          </ul>
        )}
        <div>
          {dataOfService.serviceType && dataPackaging.amount && (
            <OptionsSolution setDataPackaging={setDataPackaging} />
          )}
          {dataOfService.amount && dataPackaging.sender && (
            <OPSender setDataPackaging={setDataPackaging} />
          )}
          {dataOfService.senderData &&
            dataOfService.amount === 1 &&
            dataPackaging.receptor && (
              <OPReceptor setDataPackaging={setDataPackaging} />
            )}
          {dataOfService.packetAndReceptorData &&
            dataOfService.amount === 1 &&
            dataPackaging.content && (
              <OPContent
                setDataPackaging={setDataPackaging}
                setComplete={setComplete}
                complete={complete}
              />
            )}
          {dataOfService.senderData &&
            (dataOfService.amount > 1 || dataOfService.amount === "more") &&
            dataPackaging.upload && (
              <MPUpload
                setDataPackaging={setDataPackaging}
                setComplete={setComplete}
                complete={complete}
              />
            )}
        </div>
      </MainNewPackageContainer>
    ) : (
      <TabResumeReceptors
        setDataPackaging={setDataPackaging}
        setComplete={setComplete}
        needSupport={needSupport}
        setNeedSupport={setNeedSupport}
        mounted={mounted}
      />
    )
  }

  return (
    <main className="base">
      <Wrapper home>
        {basePackage}
      </Wrapper>
    </main>
  );
}
