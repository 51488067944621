import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BoxGeneral } from "../../styles/boxesGeneral";
import { Title4, Text, ErrorText } from "../../styles/textGeneral";
import {
  InputContainer,
  FormContainer,
  InputSelect,
} from "../../styles/inputGeneral";
import { Button } from "../../styles/buttonGeneral";
import { ReactComponent as ArrowSVG } from "./../../icons/arrow-left.svg";

//Import Contexts
import { AppContext } from "../../App";

//Import ALGORITHMS
import { capitalize } from "../../algorithms/general/capitalize";
import { getRatePrice } from "./algorithms/getRatePrice"

//Import data of location
import { departments } from "../../data/departments";
import { provinces } from "../../data/provinces";
import { districts } from "../../data/districts";
import { ubigeo } from "../../data/ubigeo";

//Validation Schema
import {
  nameFValidator,
  referenceFValidator,
  phoneFValidator,
  DNIOptionalFValidator,
  addressFValidator,
  emailReceptorFValidator,
  ecommerceNameFValidator
} from "../loginAndRegister/objects/formValidators";

export default function OPReceptor({ setDataPackaging }) {
  const [t] = useTranslation("main");
  const {
    setDataOfService,
    dataOfService,
    setLocalUniqueReceptorData,
    localUniqueReceptorData,
    dataOfUser
  } = useContext(AppContext);

  const [department, setDepartment] = useState(14); //Lima por defecto
  const [province, setProvince] = useState(127); //Lima por defecto
  const [district, setDistrict] = useState("LIMA"); //Lima por defecto

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const ubigeoData = ubigeo.find(
      (u) =>
        u.desc_prov_inei === provinces[province].province &&
        u.desc_ubigeo_inei === district
    ); // Recuperacion de ubigeo inei
    data.ubigeo = ubigeoData;
    data.statesOfUbigeo = { department, province, district };
    let ratePrice
    if(dataOfUser.typeOfUser === "B2C") {
      ratePrice = getRatePrice(ubigeoData, dataOfService);
      console.log(ratePrice)
      data.ratePrice = ratePrice;
    }
    setDataOfService({ packetAndReceptorData: [data], totalPrice: ratePrice });
    setLocalUniqueReceptorData(data);
    setDataPackaging({ receptor: false, content: true });
  };

  useEffect(() => {
    // Recuperador de estado en departamento, provincia y distrito
    localUniqueReceptorData.statesOfUbigeo &&
      (() => {
        setDepartment(localUniqueReceptorData.statesOfUbigeo.department);
        setProvince(localUniqueReceptorData.statesOfUbigeo.province);
        setDistrict(localUniqueReceptorData.statesOfUbigeo.district);
      })();
  }, [dataOfService.serviceType]);

  return (
    <main className="base">
      <BoxGeneral>
        <div
          className="titleWithArrow"
          onClick={() => setDataPackaging({ sender: true, receptor: false })}
        >
          <ArrowSVG />
          <Title4>{`${t("onePacket.receptorInformation")}  - (${
            dataOfService.serviceType === "same-day"
              ? "Same Day"
              : dataOfService.serviceType === "next-day"
              ? "Next day"
              : "A provincia"
          })`}</Title4>
        </div>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label htmlFor={"name"}>{t("onePacket.fullName")}</label>
              <input
                type={"text"}
                {...register("name", nameFValidator)}
                defaultValue={localUniqueReceptorData?.name}
              />
              {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
            </InputContainer>
            <InputContainer>
              <label htmlFor={"phone"}>{t("signInAndUp.phone")}</label>
              <input
                type={"number"}
                {...register("phone", phoneFValidator)}
                defaultValue={localUniqueReceptorData?.phone}
              />
              {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
            </InputContainer>
          </div>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label htmlFor={"email"}>{t("signInAndUp.email")}</label>
              <input
                type={"email"}
                id={"email"}
                {...register("email", emailReceptorFValidator)}
                defaultValue={localUniqueReceptorData?.email}
              />
              {errors.personalEmail && (
                <ErrorText>{errors.personalEmail.message}</ErrorText>
              )}
            </InputContainer>
            <InputContainer>
              <label htmlFor={"ecommerceName"}>{t("packet.ecommerceName")}</label>
              <input
                type={"ecommerceName"}
                id={"ecommerceName"}
                {...register("ecommerceName", ecommerceNameFValidator)}
                defaultValue={localUniqueReceptorData?.email}
              />
              {errors.personalEmail && (
                <ErrorText>{errors.personalEmail.message}</ErrorText>
              )}
            </InputContainer>
          </div>
          <div>
            <InputContainer>
              <label>{t("onePacket.document")}</label>
            </InputContainer>
            <label className="inputType inputRadioContent">
              <input
                type="radio"
                name="documentType"
                defaultChecked={
                  localUniqueReceptorData?.documentType ===
                    t("signInAndUp.documentId") ||
                  !dataOfService.packetAndReceptorData
                    ? true
                    : false
                }
                {...register("documentType", { required: true })}
                value={t("signInAndUp.documentId")}
              />
              <Text>{t("signInAndUp.documentId")}</Text>
              <input
                type="radio"
                name="documentType"
                defaultChecked={
                  localUniqueReceptorData?.documentType === t("onePacket.ce")
                    ? true
                    : false
                }
                {...register("documentType", { required: true })}
                value={t("onePacket.ce")}
              />
              <Text>{t("onePacket.ce")}</Text>
              <input
                type="radio"
                name="documentType"
                defaultChecked={
                  localUniqueReceptorData?.documentType === t("onePacket.other")
                    ? true
                    : false
                }
                {...register("documentType", { required: true })}
                value={t("onePacket.other")}
              />
              <Text>{t("onePacket.other")}</Text>
            </label>
            <div className="inputContainerDuplo">
              <InputContainer>
                <label htmlFor={"documentId"}></label>
                <input
                  placeholder="Ingrese documento"
                  type={"number"}
                  {...register("documentId", DNIOptionalFValidator)}
                  defaultValue={localUniqueReceptorData?.documentId}
                />
                {errors.documentId && (
                  <ErrorText>{errors.documentId.message}</ErrorText>
                )}
              </InputContainer>
            </div>
          </div>
          <div className="inputContainerDuplo">
            <InputContainer>
                <label htmlFor={"address"}>{t("signInAndUp.address")}</label>
                <input
                  type={"text"}
                  {...register("address", addressFValidator)}
                  defaultValue={localUniqueReceptorData?.address}
                />
                {errors.address && (
                  <ErrorText>{errors.address.message}</ErrorText>
                )}
            </InputContainer>
            <InputContainer>
              <label htmlFor={"reference"}>{t("onePacket.reference")}</label>
              <input
                type={"text"}
                {...register("reference", referenceFValidator)}
                defaultValue={localUniqueReceptorData?.reference}
              />
              {errors.reference && (
                <ErrorText>{errors.reference.message}</ErrorText>
              )}
            </InputContainer>
          </div>
          <div className="inputContainerDuplo">
            <InputSelect>
              <label>{t("signInAndUp.department")}</label>
              <select
                id={"departmentInput"}
                value={department}
                onChange={(e) => {
                  let filterPD = provinces.filter(
                    (prov) =>
                      prov.deptId === parseInt(e.target.selectedOptions[0].id)
                  )[0].provinceIndex; //Filtro de la 1era provincia
                  setDepartment(parseInt(e.target.selectedOptions[0].id));
                  setProvince(filterPD);
                  setDistrict(
                    districts.filter((dist) => dist.provId === filterPD)[0]
                      .district
                  ); //Filtro del 1er distrito de la provincia
                }}
              >
                {departments.map((dep, index) => {
                  //Iterador de departamentos
                  if (
                    (dataOfService.serviceType === "next-day" ||
                      dataOfService.serviceType === "same-day") &&
                    (dep === "LIMA" || dep === "CALLAO")
                  ) {
                    return (
                      <option value={index} key={dep} id={index}>
                        {capitalize(dep)}
                      </option>
                    );
                  } else if (dataOfService.serviceType === "province") {
                    return (
                      <option value={index} key={dep} id={index}>
                        {capitalize(dep)}
                      </option>
                    );
                  }
                })}
              </select>
            </InputSelect>
            <InputSelect>
              <label>{t("signInAndUp.province")}</label>
              <select
                id={"provinceInput"}
                value={province}
                onChange={(e) => {
                  setProvince(parseInt(e.target.selectedOptions[0].accessKey));
                  setDistrict(
                    districts.filter(
                      (dist) =>
                        dist.provId ===
                        parseInt(e.target.selectedOptions[0].accessKey)
                    )[0].district
                  ); // Filtro del 1er distrito de una provincia
                }}
              >
                {provinces
                  .filter((prov) => prov.deptId === department)
                  .map((prov) => {
                    if (
                      (dataOfService.serviceType === "next-day" ||
                        dataOfService.serviceType === "same-day") &&
                      (prov.province === "LIMA" || prov.province === "CALLAO")
                    ) {
                      return (
                        <option
                          value={prov.provinceIndex}
                          key={`prov_${prov.province}`}
                          accessKey={prov.provinceIndex}
                        >
                          {capitalize(prov.province)}
                        </option>
                      );
                    } else if (dataOfService.serviceType === "province") {
                      return (
                        <option
                          value={prov.provinceIndex}
                          key={`prov_${prov.province}`}
                          accessKey={prov.provinceIndex}
                        >
                          {capitalize(prov.province)}
                        </option>
                      );
                    }
                  })}
              </select>
            </InputSelect>
          </div>
          <div className="inputContainerDuplo">
            <InputSelect>
              <label>{t("signInAndUp.district")}</label>
              <select
                id={"districtInput"}
                value={district}
                onChange={(e) =>
                  setDistrict(e.target[e.target.selectedIndex].accessKey)
                }
              >
                {districts
                  .filter((dist) => dist.provId === province)
                  .map((dist) => (
                    <option
                      value={dist.district}
                      key={`dist_${dist.district}`}
                      accessKey={dist.district}
                    >
                      {capitalize(dist.district)}
                    </option>
                  ))}
              </select>
            </InputSelect>
          </div>
          <div className="inputContainerDuplo">
            <div></div>
            <Button primary large type="submit">
              {t("packet.nextButton")}
            </Button>
          </div>
        </FormContainer>
      </BoxGeneral>
    </main>
  );
}
