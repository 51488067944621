import styled from 'styled-components';

export const ShippingGroupCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875em;

  span {
    font-weight: ${props => props.theme.weight.medium};
  }

  a {
    color: ${props => props.theme.brandClassicBlueColor};
    font-weight: ${props => props.theme.weight.semiBold};

    &:hover {
      text-decoration: underline;
    }
  }

  .descriptionContainer {
    display: flex;
    margin: 7px 0 0 0;

    p:first-child {
      margin: 0 30px 0 0;
    }
  }
`
