import { collection, query, where, getDocs } from "firebase/firestore";

export const numberOfSends = async (
  db,
  currentUser,
  setRecoveredNumberOfSends,
  mounted
) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, "senders"),
      where("senderUid", "==", currentUser.uid),
      where("status", "==", "ENTREGADO")
    )
  );
  mounted.current && setRecoveredNumberOfSends(querySnapshot.docs.length);
};
