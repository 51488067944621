import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../App";
import { BoxGeneral, Table } from "./../../styles/boxesGeneral";
import { Title4 } from "./../../styles/textGeneral";
import { useTranslation } from "react-i18next";
import { Button } from "./../../styles/buttonGeneral";
import { TabHistoryButtonContent } from "./styles/sTabShippingHistory";
import MainSpinner from "../spinner/MainSpinner";

//Import ALGORITHMS
import { recoverLatest50Shippings } from "./algorithms/recoverLatest50Shippings";
import { capitalize } from "../../algorithms/general/capitalize";
import { createXlsxFromData } from "../../algorithms/general/createXlsxFromData";

//Import firebase utils
import { useFirestore } from "reactfire";

export default function TabShippingHistory() {
  const [t] = useTranslation("main");
  const db = useFirestore();
  const { currentUser } = useContext(AppContext);
  const [recoveredLatest50Shippings, setRecoveredLatest50Shippings] =
    useState(null);

  useEffect(() => {
    currentUser &&
      recoverLatest50Shippings(db, currentUser, setRecoveredLatest50Shippings);
  }, []);

  if (recoveredLatest50Shippings === null) return <MainSpinner />;

  return (
    <BoxGeneral>
      <Title4>{t("shippingHistory.latest50Shippings")}</Title4>
      <div className="tableContent">
        {recoveredLatest50Shippings && recoveredLatest50Shippings.length > 0 ? (
          <Table>
            <thead>
              <tr className="tableHead">
                <th>{t("table.number")}</th>
                <th>{t("table.name")}</th>
                <th>{t("table.telephone")}</th>
                <th>{t("table.email")}</th>
                <th>{t("table.district")}</th>
                <th>{t("table.province")}</th>
                <th>{t("table.description")}</th>
                <th>{t("table.trackingID")}</th>
                <th>{t("table.state")}</th>
              </tr>
              {recoveredLatest50Shippings.map((s, index) => {
                return (
                  <tr key={s.name.concat(index + 1)}>
                    <th className="tableNumber">{index + 1}</th>
                    <th>{s.name ? capitalize(s.name) : ""}</th>
                    <th>{s.phone && s.phone}</th>
                    <th>{s.email ? s.email : ""}</th>
                    <th>
                      {s.desc_ubigeo_inei ? capitalize(s.desc_ubigeo_inei) : ""}
                    </th>
                    <th>
                      {s.desc_prov_inei ? capitalize(s.desc_prov_inei) : ""}
                    </th>
                    <th>
                      {s.packetDescription
                        ? capitalize(s.packetDescription)
                        : ""}
                    </th>
                    <th>{s.receptorCode && s.receptorCode}</th>
                    <th>
                      {s.status
                        ? capitalize(s.status) === "No Asignado"
                          ? "No Asignado"
                          : capitalize(s.status) === "Para Despacho" ||
                            capitalize(s.status) === "En Gestion"
                          ? "Pendiente"
                          : capitalize(s.status) === "Entregado"
                          ? capitalize(s.status)
                          : capitalize(s.status) === "Motivo"
                          ? s.statusDeterminant
                            ? capitalize(s.statusDeterminant)
                            : "No Entregado"
                          : capitalize(s.status) === "No distribuido" &&
                            (s.statusDeterminant
                              ? capitalize(s.statusDeterminant)
                              : "No Entregado")
                        : "No Asignado"}
                    </th>
                  </tr>
                );
              })}
            </thead>
          </Table>
        ) : (
          "Aún no haz realizado envíos."
        )}
      </div>
      <TabHistoryButtonContent>
        {recoveredLatest50Shippings && recoveredLatest50Shippings.length > 0 ? (
          <Button
            terciary
            large
            onClick={() =>
              createXlsxFromData(recoveredLatest50Shippings, "lastest50", "sender")
            }
          >
            {t("shippingHistory.buttonXlsxDownload")}
          </Button>
        ) : (
          <Button
            disabled
            terciary
            large
          >
            {t("shippingHistory.buttonXlsxDownload")}
          </Button>
        )}
      </TabHistoryButtonContent>
    </BoxGeneral>
  );
}
