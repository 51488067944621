export const ratePrices = [
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"BARRANCO",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"SURQUILLO",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"SURCO",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"MAGDALENA",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"MIRAFLORES",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"SAN ISIDRO",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"LA VICTORIA",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"LINCE",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"SAN LUIS",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"SAN BORJA",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"JESUS MARIA",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:8.26
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"BREÑA",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:11.8
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"PUEBLO LIBRE",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:10.5
	},
	{
		typeZone:"CALLAO",
    department:"Lima",
    province:"Lima",
		district:"SAN MIGUEL",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:10.5
	},
	{
		typeZone:"LIMA ESTE_1",
    department:"Lima",
    province:"Lima",
		district:"SALAMANCA",
		zone:"CENTRICO",
		nextDayRate:11,
		sameDayRate:11.8
	},
	{
		typeZone:"LIMA ESTE_1",
    department:"Lima",
    province:"Lima",
		district:"ATE",
		zone:"CENTRICO",
		nextDayRate:11,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA ESTE_1",
    department:"Lima",
    province:"Lima",
		district:"EL AGUSTINO",
		zone:"CENTRICO",
		nextDayRate:11,
		sameDayRate:11.8
	},
	{
		typeZone:"LIMA ESTE_1",
    department:"Lima",
    province:"Lima",
		district:"LA MOLINA",
		zone:"CENTRICO",
		nextDayRate:11,
		sameDayRate:11.8
	},
	{
		typeZone:"CALLAO",
    department:"Callao",
    province:"Callao",
		district:"BELLAVISTA",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:11.8
	},
	{
		typeZone:"CALLAO",
    department:"Callao",
    province:"Callao",
		district:"CALLAO",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:11.8
	},
	{
		typeZone:"CALLAO",
    department:"Callao",
    province:"Callao",
		district:"CARMEN DE LA LEGUA REYNOSO",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:11.8
	},
	{
		typeZone:"LIMA CENTRO",
    department:"Lima",
    province:"Lima",
		district:"LIMA",
		zone:"CENTRICO",
		nextDayRate:8,
		sameDayRate:10.5
	},
	{
		typeZone:"LIMA SUR",
    department:"Lima",
    province:"Lima",
		district:"CHORRILLOS",
		zone:"CENTRICO",
		nextDayRate:14,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA NORTE",
    department:"Lima",
    province:"Lima",
		district:"COMAS",
		zone:"PERIFERICO",
		nextDayRate:10.5,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA NORTE",
    department:"Lima",
    province:"Lima",
		district:"INDEPENDENCIA",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA ESTE_1",
    department:"Lima",
    province:"Lima",
		district:"SANTA ANITA",
		zone:"CENTRICO",
		nextDayRate:11,
		sameDayRate:11.8
	},
	{
		typeZone:"LIMA ESTE_1",
    department:"Lima",
    province:"Lima",
		district:"ATE - HUAYCAN",
		zone:"PERIFERICO",
		nextDayRate:17.7,
		sameDayRate:17.7
	},
	{
		typeZone:"CALLAO",
    department:"Callao",
    province:"Callao",
		district:"LA PERLA",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:11.8
	},
	{
		typeZone:"CALLAO",
    department:"Callao",
    province:"Callao",
		district:"LA PUNTA",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:11.8
	},
	{
		typeZone:"LIMA NORTE",
    department:"Lima",
    province:"Lima",
		district:"LOS OLIVOS",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:11.8
	},
	{
		typeZone:"LIMA NORTE",
    department:"Lima",
    province:"Lima",
		district:"RIMAC",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:11.8
	},
	{
		typeZone:"LIMA SUR",
    department:"Lima",
    province:"Lima",
		district:"SAN JUAN DE MIRAFLORES",
		zone:"PERIFERICO",
		nextDayRate:14,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA ESTE_1",
    department:"Lima",
    province:"Lima",
		district:"ATE - SANTA CLARA",
		zone:"PERIFERICO",
		nextDayRate:17.7,
		sameDayRate:17.7
	},
	{
		typeZone:"LIMA ESTE_2",
    department:"Lima",
    province:"Lima",
		district:"SAN JUAN DE LURIGANCHO",
		zone:"PERIFERICO",
		nextDayRate:15.34,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA NORTE",
    department:"Lima",
    province:"Lima",
		district:"SAN MARTIN DE PORRES",
		zone:"CENTRICO",
		nextDayRate:10.5,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA SUR",
    department:"Lima",
    province:"Lima",
		district:"VILLA EL SALVADOR",
		zone:"PERIFERICO",
		nextDayRate:14,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA SUR",
    department:"Lima",
    province:"Lima",
		district:"VILLA MARIA DEL TRIUNFO",
		zone:"PERIFERICO",
		nextDayRate:14,
		sameDayRate:15.34
	},
	{
		typeZone:"LIMA NORTE",
    department:"Lima",
    province:"Lima",
		district:"CARABAYLLO",
		zone:"PERIFERICO",
		nextDayRate:16,
		sameDayRate:17.7
	},
	{
		typeZone:"LIMA ESTE__3",
    department:"Lima",
    province:"Lima",
		district:"CHACLACAYO",
		zone:"PERIFERICO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"LIMA ESTE__3",
    department:"Lima",
    province:"Lima",
		district:"LURIGANCHO",
		zone:"PERIFERICO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"LIMA ESTE__3",
    department:"Lima",
    province:"Lima",
		district:"CIENEGUILLA",
		zone:"PERIFERICO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"LIMA SUR",
    department:"Lima",
    province:"Lima",
		district:"LURIN",
		zone:"PERIFERICO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"LIMA SUR",
    department:"Lima",
    province:"Lima",
		district:"PACHACAMAC",
		zone:"PERIFERICO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"LIMA NORTE",
    department:"Lima",
    province:"Lima",
		district:"PUENTE PIEDRA",
		zone:"PERIFERICO",
		nextDayRate:16,
		sameDayRate:17.7
	},
	{
		typeZone:"BALNEARIO",
    department:"Lima",
    province:"Lima",
		district:"SANTA ROSA",
		zone:"PERIFERICO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"LIMA NORTE",
    department:"Lima",
    province:"Lima",
		district:"VENTANILLA",
		zone:"PERIFERICO",
		nextDayRate:16,
		sameDayRate:17.7
	},
	{
		typeZone:"BALNEARIO",
    department:"Lima",
    province:"Lima",
		district:"ANCON",
		zone:"PERIFERICO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
	  typeZone:"BALNEARIO",
    department:"Lima",
    province:"Lima",
		district:"PUCUSANA",
		zone:"BALNEARIO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"BALNEARIO",
    department:"Lima",
    province:"Lima",
		district:"PUNTA HERMOSA",
		zone:"BALNEARIO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"BALNEARIO",
    department:"Lima",
    province:"Lima",
		district:"PUNTA NEGRA",
		zone:"BALNEARIO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"BALNEARIO",
    department:"Lima",
    province:"Lima",
		district:"SAN BARTOLO",
		zone:"BALNEARIO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"BALNEARIO",
    department:"Lima",
    province:"Lima",
		district:"SANTA MARIA DEL MAR",
		zone:"BALNEARIO",
		nextDayRate:29.5,
		sameDayRate:29.5
	},
	{
		typeZone:"CALLAO",
    department:"Callao",
    province:"Callao",
		district:"MI PERU",
		zone:"PERIFERICO",
		nextDayRate:29.5,
		sameDayRate:29.5
	}
]