import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { Wrapper } from './../../../styles/generalStyles';
import LogoServicioPuntual from './../../../images/logos/image-24.png'
import { ReactComponent as MenuSVG } from './../../../icons/menu.svg'
import { HeaderLandingContainer } from './styles/sHeaderLanding'
import { Link } from 'react-router-dom'
import MainModal from './../../modal/MainModal';
import NavbarHome from './../menu/NavbarHome';

export default function HeaderHome() {
  const [t] = useTranslation("main");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  return (
    <header>
      <HeaderLandingContainer home>
        <Wrapper>
          <Link to="/">
            <img src={LogoServicioPuntual} alt="Logo principal de Serivico Puntual" />
          </Link>
          <div className='headerLandingMenuContent'>
            <MenuSVG onClick={openModal} />
          </div>
        </Wrapper>
      </HeaderLandingContainer>
      <MainModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        isClose={modalIsOpen}
        background="#174F82"
        width="-webkit-fill-available"
        height= "-webkit-fill-available"
        borderRadius="none"
      >
        <NavbarHome closeModal={closeModal}/>
      </MainModal>
    </header>
  )
}
