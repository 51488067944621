import React, { useState, useEffect } from "react";
import { BoxBorder } from "./../../styles/boxesGeneral";
import {
  RecentShipmentDetails,
  RecentShipmentContainer,
} from "./../home/styles/sRecentShipment";
import { Button } from "./../../styles/buttonGeneral";
import { UserSearchCardContainer } from "./styles/sUserSearchCard";
import MainModal from "./../modal/MainModal";
import ModifyTypeUser from "./../general/cards/ModifyTypeUser";

export default function UserSearchCard({
  userToChangeType,
  setUserToChangeType,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {}, [userToChangeType]);

  return (
    <>
      <li>
        <UserSearchCardContainer>
          <BoxBorder>
            <RecentShipmentContainer withButton>
              <RecentShipmentDetails>
                <div className="recentShipmentDetailsContent tripleRowD">
                  <div className="tripleRowContent">
                    <div className="recentShipmentDetail">
                      <p>Email: </p>
                      <span>{userToChangeType?.email}</span>
                    </div>
                    {userToChangeType && userToChangeType.bussinesEmail && (
                      <div className="recentShipmentDetail">
                        <p>Email corporativo: </p>
                        <span>{userToChangeType.bussinesEmail}</span>
                      </div>
                    )}
                    <div className="recentShipmentDetail">
                      <p>Tel.: </p>
                      <span>{userToChangeType?.personalPhone}</span>
                    </div>
                  </div>
                  <div className="tripleRowContent">
                    {userToChangeType && userToChangeType.bussinesPhone && (
                      <div className="recentShipmentDetail">
                        <p>Tel. corporativo: </p>
                        <span>{userToChangeType.bussinesPhone}</span>
                      </div>
                    )}
                    <div className="recentShipmentDetail">
                      <p>Nombre: </p>
                      <span>
                        {userToChangeType && userToChangeType.name?.prevName}
                      </span>
                    </div>
                  </div>
                  <div className="tripleRowContent">
                    <div className="recentShipmentDetail">
                      <p>Empresa: </p>
                      <span>{userToChangeType?.bussinesName}</span>
                    </div>
                    <div className="recentShipmentDetail">
                      <p>Tipo de usuario: </p>
                      <span>
                        {userToChangeType &&
                        userToChangeType.typeOfUser &&
                        (userToChangeType.typeOfUser === "B2B" ||
                          userToChangeType.typeOfUser === "B2C")
                          ? userToChangeType.typeOfUser
                          : "ADMIN"}
                      </span>
                    </div>
                  </div>
                </div>
              </RecentShipmentDetails>
              {userToChangeType &&
              userToChangeType.typeOfUser &&
              (userToChangeType.typeOfUser === "B2B" ||
                userToChangeType.typeOfUser === "B2C") ? (
                <Button terciary small onClick={openModal}>
                  Cambiar tipo
                </Button>
              ) : (
                <Button terciary small disabled>
                  Cambiar tipo
                </Button>
              )}
            </RecentShipmentContainer>
          </BoxBorder>
        </UserSearchCardContainer>
      </li>
      <MainModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        isClose={modalIsOpen}
        background="#F8FAFD"
        width="auto"
        height="auto"
        borderRadius="8px"
        fillColor="#262A2E"
      >
        <ModifyTypeUser
          paddingModal="30px 60px"
          closeModal={closeModal}
          userToChangeType={userToChangeType}
          setUserToChangeType={setUserToChangeType}
        />
      </MainModal>
    </>
  );
}
