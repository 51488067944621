import React, { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { BoxGeneral } from "./../../styles/boxesGeneral";
import { Title4, Text } from "./../../styles/textGeneral";
import { ReactComponent as Package1 } from "./../../icons/package-1.svg";
import { ReactComponent as Packages } from "./../../icons/packages.svg";
import { OptionsColutionContainer } from "./styles/sOptionsSolution";
import { ReactComponent as ArrowSVG } from "./../../icons/arrow-left.svg";

export default function OptionsSolution({ setDataPackaging }) {
  const [t] = useTranslation("main");

  const { setDataOfService, dataOfService } = useContext(AppContext);

  const optionsList = [
    {
      icon: <Package1 />,
      text: `${t("newShipment.onlyOnePacket")}`,
      amount: 1,
    },
    {
      icon: <Packages />,
      text: `${t("newShipment.twoOrMorePacket")}`,
      amount: "more",
    },
  ];

  return (
    <>
      <BoxGeneral>
        <div className="titleWithArrow">
          {window.innerWidth <= 1200 && (
            <ArrowSVG
              onClick={() =>
                setDataPackaging({ solutionCard: true, amount: false })
              }
            />
          )}
          <Title4>{`${t("newShipment.selectAnOption")} - (${
            dataOfService.serviceType === "same-day"
              ? "Same Day"
              : dataOfService.serviceType === "next-day"
              ? "Next day"
              : "A provincia"
          })`}</Title4>
        </div>
        <OptionsColutionContainer>
          {optionsList.map((item) => (
            <li key={item.text}>
              <button
                onClick={() => {
                  setDataOfService({ amount: item.amount });
                  setDataPackaging({ amount: false, sender: true });
                }}
              >
                {item.icon}
                <Text>{item.text}</Text>
              </button>
            </li>
          ))}
        </OptionsColutionContainer>
      </BoxGeneral>
    </>
  );
}
