import { doc, setDoc} from 'firebase/firestore'

export const updateProfileUser = (db, data, uid) => {
    try {
        const {dni, email, firstLastName, name, personalPhone, secondLastName} = data
        const docUserRef = doc(db, 'users', uid)
        setDoc(docUserRef, {
            DNI: dni,
            email: email,
            name: {
                firstName: name,
                lastName: `${firstLastName} ${secondLastName}`,
            },
            personalPhone,
        }, 
        { merge: true}).then(() => {
            console.log("Profile Updated")
            window.location.reload()
        }).catch(err => {
            console.error(err.message);
        })

    } catch (err) {
        console.error(err.message)
    }
}