import React from 'react'
import { Wrapper } from './../../../styles/generalStyles'
import LogoServicioPuntual from './../../../images/logos/image-2.png'
import { HeaderLandingContainer } from './styles/sHeaderLanding'
import { Button } from './../../../styles/buttonGeneral'
import { Link } from 'react-router-dom'

export default function HeaderTwo({ button, service }) {
  return (
    <header>
      <Wrapper>
        <HeaderLandingContainer>
          <Link to="/">
            <img src={LogoServicioPuntual} alt="Logo principal de Serivico Puntual" />
            <p className='logoService'>{service}</p>
          </Link>
          <div className='headerLandingMenuContent'>
            <Link to="/">
              <Button secondary small>{button}</Button>
            </Link>
          </div>
        </HeaderLandingContainer>
      </Wrapper>
    </header>
  )
}
