import React from 'react'
import { BoxGeneral } from '../../styles/boxesGeneral'
import { Title4 } from "./../../styles/textGeneral";
import { DetailsTrackingCardContainer } from './styles/sDetailsTrackingCard'
import { DeliveredDetailsCardContainer } from './styles/sDeliveredDetailsCard'

export default function DeliveredDetailsCard() {
  return (
    <BoxGeneral shadow>
      <Title4>Detalles de la entrega</Title4>
      <DeliveredDetailsCardContainer>
        <DetailsTrackingCardContainer>
          <p>Destinatario: <span>Maria Molina</span></p>
          <p>Evidencia de recepción:</p>
        </DetailsTrackingCardContainer>
        <img alt="imagen de envidencia de entrega"></img>
      </DeliveredDetailsCardContainer>
    </BoxGeneral>
  )
}
