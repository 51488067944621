import { useState, useEffect } from 'react'
import { collection, query, where, getDocs, orderBy, onSnapshot, doc, limit } from "firebase/firestore";

const RecoverResumeReceptors = (
    db,
    currentUser,
    amount
) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [receptorData, setReceptorData] = useState([])
    const ref = collection(db, "receptors")
    const q = query(ref, 
        where("senderUid", "==", currentUser.uid), 
        where("payState", "==", "POR PAGAR"), 
        orderBy('sendNumberGenenerateDate', 'desc'),
        limit(amount)
        )
    
    useEffect(() => {
        const unsubscribe  = onSnapshot(
            q,
            (doc) => {
                if(doc.empty) {
                    console.error("No existen datos")
                    setLoading(false)
                } else {
                    const dataCollect = doc.docs.map(item => {
                        return {
                            id: item.id,
                            ...item.data()
                        }
                    })
                    setReceptorData(dataCollect)
                    setLoading(false)
                }
                },
                (error) => {
                    console.error(error.message)
                    setError(true)
                    setLoading(false)
            }
        )
        return () => {
            unsubscribe()
        } 
    }, [])
    return [receptorData, error, loading]
};

export default RecoverResumeReceptors