import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { createXlsxFromData } from "../../../algorithms/general/createXlsxFromData";

export const recoverAllShippings = async (db, currentUser) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, "receptors"),
      where("senderUid", "==", currentUser.uid),
      orderBy("sendNumberGenenerateDate", "desc")
    )
  );

  let listOfShipments = [];
  querySnapshot.docs.map((s) => {
    return listOfShipments.push(s.data());
  });
  createXlsxFromData(listOfShipments, "all", "sender");
};
