import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BoxGeneral } from "../../styles/boxesGeneral";
import { Title4, ErrorText, Text } from "../../styles/textGeneral";
import { InputContainer, FormContainer } from "../../styles/inputGeneral";
import { Button } from "../../styles/buttonGeneral";
import { ReactComponent as ArrowSVG } from "./../../icons/arrow-left.svg";

//Import Contexts
import { AppContext } from "../../App";

//Validation Schema
import {
  contentFValidator,
  measureFValidator,
  weightFValidator,
  priceFValidator,
} from "../loginAndRegister/objects/formValidators";

export default function OPContent({ setDataPackaging, setComplete, complete }) {
  const [t] = useTranslation("main");
  const {
    setDataOfService,
    dataOfService,
    setLocalUniqueReceptorData,
    localUniqueReceptorData,
  } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setDataOfService({
      packetAndReceptorData: [
        Object.assign({}, dataOfService.packetAndReceptorData[0], data),
      ],
    });
    setDataPackaging({ content: false });
    setLocalUniqueReceptorData(data);
    setComplete(true);
  };

  return (
    <main className="base">
      <BoxGeneral>
        <div
          className="titleWithArrow"
          onClick={() => setDataPackaging({ receptor: true, content: false })}
        >
          <ArrowSVG />
          <Title4>{`${t("packetContent.contentInformation")} - (${
            dataOfService.serviceType === "same-day"
              ? "Same Day"
              : dataOfService.serviceType === "next-day"
              ? "Next day"
              : "A provincia"
          })`}</Title4>
        </div>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <label htmlFor={"packetDescription"}>
              {t("packetContent.content")}
            </label>
            <input
              type={"text"}
              {...register("packetDescription", contentFValidator)}
              defaultValue={localUniqueReceptorData?.packetDescription}
            />
            {errors.packetDescription && (
              <ErrorText>{errors.packetDescription.message}</ErrorText>
            )}
          </InputContainer>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label htmlFor={"height"}>{t("packetContent.height")}</label>
              <input
                type={"text"}
                {...register("height", measureFValidator)}
                defaultValue={localUniqueReceptorData?.height}
              />
              {errors.height && <ErrorText>{errors.height.message}</ErrorText>}
            </InputContainer>
            <InputContainer>
              <label htmlFor={"width"}>{t("packetContent.width")}</label>
              <input
                type={"text"}
                {...register("width", measureFValidator)}
                defaultValue={localUniqueReceptorData?.width}
              />
              {errors.width && <ErrorText>{errors.width.message}</ErrorText>}
            </InputContainer>
          </div>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label htmlFor={"large"}>{t("packetContent.large")}</label>
              <input
                type={"text"}
                {...register("large", measureFValidator)}
                defaultValue={localUniqueReceptorData?.large}
              />
              {errors.large && <ErrorText>{errors.large.message}</ErrorText>}
            </InputContainer>
            <InputContainer>
              <label htmlFor={"weight"}>{t("packetContent.weight")}</label>
              <input
                type={"text"}
                {...register("weight", weightFValidator)}
                defaultValue={localUniqueReceptorData?.weight}
              />
              {errors.weight && <ErrorText>{errors.weight.message}</ErrorText>}
            </InputContainer>
          </div>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label>{t("packetContent.value")}</label>
              <input
                type={"text"}
                {...register("value", priceFValidator)}
                defaultValue={localUniqueReceptorData?.value}
              />
              {errors.value && <ErrorText>{errors.value.message}</ErrorText>}
            </InputContainer>
          </div>
          <label className="inputType inputCheckboxContent">
            <input
              type="checkbox"
              {...register("fragile")}
              defaultChecked={localUniqueReceptorData?.fragile}
            />
            <Text>{t("packetContent.fragile")}</Text>
          </label>
          <div className="inputContainerDuplo">
            <div></div>
            <Button primary large type="submit">
              {t("packet.nextButton")}
            </Button>
          </div>
        </FormContainer>
      </BoxGeneral>
    </main>
  );
}
