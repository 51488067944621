import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./../../styles/generalStyles";
import { BoxGeneral } from "./../../styles/boxesGeneral";
import { Title4 } from "./../../styles/textGeneral";
import RecentShipment from "./RecentShipment";
import { ReactComponent as ProfileSVG } from "./../../icons/user.svg";
import { MainHomeProfileContent, MainHomeContainer } from "./styles/sMainHome";
import StatisticCard from "./StatisticCard";
import AddsCard from "./AddsCard";
import { Link, useNavigate } from "react-router-dom";
import MainSpinner from "../spinner/MainSpinner";

//Import ALGORITHMS
import { capitalize } from "../../algorithms/general/capitalize";
import { recoverRecentShipments } from "./algorithms/recoverRecentShipments";

//Import firebase utils
import { useFirestore } from "reactfire";

import useMounted  from '../../hooks/useMounted'

export default function MainHome() {
  const [t] = useTranslation("main");
  const navigate = useNavigate();
  const { currentUser, dataOfUser } = useContext(AppContext);
  const db = useFirestore();
  const [recoveredRecentShipments, setRecoveredRecentShipments] = useState(null);
  const mounted = useMounted()

  useEffect(() => {
    !currentUser && navigate("/");
    currentUser &&
      recoverRecentShipments(db, currentUser, setRecoveredRecentShipments, mounted);
  }, [currentUser, dataOfUser]);

  if(recoveredRecentShipments === null) return <MainSpinner/>

  return (
    <main className="base">
      <Wrapper home width="auto">
        <MainHomeContainer>
          <BoxGeneral>
            <MainHomeProfileContent>
              <p>
                {t("start.helloUser", {
                  name: dataOfUser
                    ? dataOfUser.name.firstName && dataOfUser.name.lastName
                      ? capitalize(
                          `${dataOfUser.name.firstName.split(" ")[0]} ${
                            dataOfUser.name.lastName.split(" ")[0]
                          }`
                        )
                      : dataOfUser.name.prevName
                      ? capitalize(dataOfUser.name.prevName)
                      : ""
                    : "",
                })}
              </p>
              <Link to="/profile">
                {dataOfUser?.photoUrl ? (
                  <img src={dataOfUser.photoUrl} alt="Foto de perfil" />
                ) : (
                  <ProfileSVG />
                )}
              </Link>
            </MainHomeProfileContent>
          </BoxGeneral>
          <BoxGeneral>
            <Title4>{t("start.recentShipments")}</Title4>
            <ul>
              {recoveredRecentShipments.length > 0
                ? recoveredRecentShipments.map((s) => {
                    return (
                      <RecentShipment
                        key={
                          s.receptorCode ||
                          s.senderUid.concat(s.cod_ubigeo_inei)
                        }
                        {...s}
                      />
                    );
                  })
                : "Aún no haz realizado envíos."}
            </ul>
          </BoxGeneral>
          <div className="gridDesktopContent">
            <BoxGeneral>
              <Title4>{t("start.shipmentStatistics")}</Title4>
              <ul>
                <StatisticCard />
              </ul>
            </BoxGeneral>
            <BoxGeneral>
              <Title4>{t("start.newShipment")}</Title4>
              <AddsCard />
            </BoxGeneral>
          </div>
        </MainHomeContainer>
      </Wrapper>
    </main>
  );
}
