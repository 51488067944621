import React, { useEffect } from "react";
import { Wrapper } from "./../../styles/generalStyles";
import { MainTrackingContainer, DesktopGrid } from "./styles/sMainTracking";
import TrackingLineCard from "./TrackingLineCard";
import DetailsTrackingCard from "./DetailsTrackingCard";
import TrackingTimelineCard from "./TrackingTimelineCard";
import DeliveredDetailsCard from "./DeliveredDetailsCard";
import NoDeliveredCard from "./NoDeliveredCard";
import { useLocation } from "react-router-dom";

export default function MainTracking() {
  const location = useLocation();

  useEffect(() => {}, []);

  return (
    <main>
      <Wrapper morePages>
        <MainTrackingContainer>
          <h2 className="trackingTitle">
            Seguimiento del envío: <span>N° {location.state.receptorCode}</span>
          </h2>
          <TrackingLineCard state={location.state} />
          <DesktopGrid>
            <TrackingTimelineCard />
            <DetailsTrackingCard details={location.state} />
          </DesktopGrid>
          <DesktopGrid>
            <DeliveredDetailsCard />
          </DesktopGrid>
          <NoDeliveredCard />
        </MainTrackingContainer>
      </Wrapper>
    </main>
  );
}
