import React, { useState, useEffect } from "react";
import { BoxBorder } from "./../../styles/boxesGeneral";
import {
  RecentShipmentDetails,
  RecentShipmentContainer,
} from "./../home/styles/sRecentShipment";
import { Button } from "./../../styles/buttonGeneral";
import { UserSearchCardContainer } from "./styles/sUserSearchCard";
import MainModal from "./../modal/MainModal";
import ModifyPayState from "./../general/cards/ModifyPayState";

//Import firebase utils
import { useFirestore } from "reactfire";

export default function RemitoSearchCard({ remito, setRemito, setShipments, recoverShipments }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const db = useFirestore();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {}, [remito]);

  return (
    <>
      <li>
        <UserSearchCardContainer>
          <BoxBorder>
            <RecentShipmentContainer withButton>
              <RecentShipmentDetails>
                <div className="recentShipmentDetailsContent tripleRowD">
                  <div className="tripleRowContent">
                    <div className="recentShipmentDetail">
                      <p>Uid: </p>
                      <span>{remito && remito.recovered?.senderUid}</span>
                    </div>
                    <div className="recentShipmentDetail">
                      <p>Nombre: </p>
                      <span>{remito && remito.recovered?.name}</span>
                    </div>
                    <div className="recentShipmentDetail">
                      <p>Tel.: </p>
                      <span>{remito && remito.recovered?.phone}</span>
                    </div>
                  </div>
                  <div className="tripleRowContent">
                    <div className="recentShipmentDetail">
                      <p>Provincia: </p>
                      <span>{remito && remito.recovered?.desc_prov_inei}</span>
                    </div>
                    <div className="recentShipmentDetail">
                      <p>Distrito: </p>
                      <span>
                        {remito && remito.recovered?.desc_ubigeo_inei}
                      </span>
                    </div>
                    <div className="recentShipmentDetail">
                      <p>Direccion: </p>
                      <span>{remito && remito.recovered?.address}</span>
                    </div>
                  </div>
                  <div className="tripleRowContent">
                    <div className="recentShipmentDetail">
                      <p>Referencia: </p>
                      <span>{remito && remito.recovered?.reference}</span>
                    </div>
                    <div className="recentShipmentDetail">
                      <p>N° de pedidos: </p>
                      <span>
                        {remito && remito.recovered.listOfReferences?.length}
                      </span>
                    </div>
                    {remito && remito.recovered?.transactionCode && (
                      <div className="recentShipmentDetail">
                        <p>Direccion: </p>
                        <span>
                          {remito && remito.recovered.transactionCode}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="tripleRowContent">
                    <div className="recentShipmentDetail">
                      <p>Estado de pago: </p>
                      <span>
                        <Button terciary small onClick={openModal}>
                          {remito && remito.recovered?.payState}
                        </Button>
                      </span>
                    </div>
                    <div className="recentShipmentDetail">
                      <p>Tipo de servicio: </p>
                      <span>{remito && remito.recovered?.serviceType}</span>
                    </div>
                    <div className="recentShipmentDetail">
                      <p>Tipo de usuario: </p>
                      <span>
                        {remito &&
                        remito.recovered &&
                        remito.recovered.typeOfUser &&
                        (remito.recovered.typeOfUser === "B2B" ||
                          remito.recovered.typeOfUser === "B2C")
                          ? remito.recovered.typeOfUser || "B2C"
                          : "ADMIN"}
                      </span>
                    </div>
                  </div>
                </div>
              </RecentShipmentDetails>
              <Button secondary small onClick={(e)=>{
                e.preventDefault();
                recoverShipments(db, remito, setShipments)
              }}>
                Ver pedidos
              </Button>
            </RecentShipmentContainer>
          </BoxBorder>
        </UserSearchCardContainer>
      </li>
      <MainModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        isClose={modalIsOpen}
        background="#F8FAFD"
        width="auto"
        height="auto"
        borderRadius="8px"
        fillColor="#262A2E"
      >
        <ModifyPayState
          paddingModal="30px 60px"
          closeModal={closeModal}
          remito={remito}
          setRemito={setRemito}
        />
      </MainModal>
    </>
  );
}
