import React, { useContext } from 'react';
import { useTranslation } from "react-i18next"
import { Wrapper } from './../../styles/generalStyles';
import { BoxGeneral } from './../../styles/boxesGeneral'
import { Text } from './../../styles/textGeneral'
import { ReactComponent as ProfileSVG } from './../../icons/user.svg'
import { MainProfileDetails, DetailsListContent, MainProfileContainer } from './styles/sMainProfile'
import { ReactComponent as BusinessSVG } from './../../icons/business.svg'
import { ReactComponent as PhoneSVG } from './../../icons/phone.svg'
import { ReactComponent as LocationSVG } from './../../icons/location.svg'
import EditProfile from './EditProfile';
import { capitalize } from '../../algorithms/general/capitalize'
import { AppContext } from '../../App' 

export default function MainProfile() {
  const [t] = useTranslation("main");
  const { dataOfUser, currentUser } = useContext(AppContext)

  const detailsList = [
    {
      icon: <BusinessSVG />,
      text: `${dataOfUser?.bussinesName ? capitalize(dataOfUser.bussinesName): 'Nombre de mi empresa'}`
    },
    {
      icon: <PhoneSVG />,
      text: `(+51) ${dataOfUser?.bussinesPhone ? capitalize(dataOfUser.bussinesPhone) : 'Mi número'}`
    },
    {
      icon: <LocationSVG />,
      text: `${dataOfUser?.department ? `${capitalize(dataOfUser.department)}, Perú` : 'Ubicación'}`
    },
  ]

  const name = dataOfUser.name?.firstName ? 
    capitalize(dataOfUser.name?.firstName) : 
    (dataOfUser.name?.prevName ? capitalize(dataOfUser.name?.prevName): '')

  return (
    <main className='base'>
      <Wrapper home>
        <MainProfileContainer>
          <BoxGeneral>
            <MainProfileDetails>
              <div className='cercleUserMainProfile'>
                { !dataOfUser?.photoUrl 
                  ? <ProfileSVG /> 
                  : <img src={dataOfUser.photoUrl} alt="Foto de perfil"/> 
                }
              </div>
              <p>{name} {dataOfUser.name?.lastName ? capitalize(dataOfUser.name?.lastName) : ''}</p>
            </MainProfileDetails>
            <DetailsListContent>
              {
                detailsList.map((detail) =>
                  <li key={detail.text}>
                    {detail.icon}
                    <Text>{detail.text}</Text>
                  </li>
                )
              }
            </DetailsListContent>
            {/* <Button terciary small>{t("profile.editProfileButton")}</Button> */}
          </BoxGeneral>
          <EditProfile 
            dataOfUser={dataOfUser} 
            currentUser={currentUser}
          />
        </MainProfileContainer>
      </Wrapper>
    </main>
  )
}
