import React, { useState, useContext } from "react";
import { useReducedState } from "../../hooks/useReducedState";
import { useTranslation } from "react-i18next";
import { BoxGeneral } from "../../styles/boxesGeneral";
import { Title4, Text } from "../../styles/textGeneral";
import { InputContainer, FormContainer } from "../../styles/inputGeneral";
import { Button } from "../../styles/buttonGeneral";
import { ReactComponent as ArrowSVG } from "./../../icons/arrow-left.svg";
import {
  MPUploadContent,
  UploadContent,
  MPUploadContainer,
  MPUploadDropSpace,
} from "./styles/sMPUpload";
import { ReactComponent as UploadSVG } from "./../../icons/upload.svg";
import MainModal from "./../modal/MainModal";
import ErrorSendXls from "./../general/cards/ErrorSendXls";

//Import ALGORITHMS
import { uploadDataArchive } from "./algorithms/uploadDataArchive";

//Import Contexts
import { AppContext } from "../../App";

export default function MPUpload({ setDataPackaging, setComplete, complete }) {
  const [t] = useTranslation("main");
  const { setDataOfService, dataOfService, dataOfUser } = useContext(AppContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadedExcelError, setUploadedExcelError] = useReducedState({
    typeServiceError: null,
    excelDataErrors: null,
    empty: false,
    otherFile: false,
  });

  const openApiFile = (e) => {
    e.preventDefault();
    let uploadArchive = document.getElementById("uploadArchive");
    e = uploadArchive.click();
  };

  const closeModal = () => setModalIsOpen(false);

  return (
    <main className="base">
      <BoxGeneral>
        <MPUploadContainer>
          <div
            className="titleWithArrow"
            onClick={() => setDataPackaging({ sender: true, upload: false })}
          >
            <ArrowSVG />
            <Title4>{`${t("manyPacket.titleManyPacket")} - (${dataOfService.serviceType === "same-day"
              ? "Same Day"
              : dataOfService.serviceType === "next-day"
                ? "Next day"
                : "A provincia"
              })`}</Title4>
          </div>
          <MPUploadContent>
            <Text>{t("manyPacket.firstParagraph")}</Text>
            <Button
              secondary
              small
              onClick={() => {
                //Apertura y cierre de pestaña de descarga de formato en excel
                let windowDownload = window.open(
                  "https://firebasestorage.googleapis.com/v0/b/servicio-puntual-b19fc.appspot.com/o/utils%2FCarga%20Masiva%20-%20Servicio%20Programado%20-%20Servicio%20Puntual.xlsm?alt=media&token=c2480000-b084-416e-abe1-7c2ab25e9ff1"
                );
                try {
                  setTimeout(() => {
                    windowDownload.close();
                  }, 7000);
                } catch {
                  console.log("Ventana previamente cerrada");
                }
              }}
            >
              {t("manyPacket.downloadFormatButton")}
            </Button>
            <Text>{t("manyPacket.secondParagraph")}</Text>
          </MPUploadContent>
          <UploadContent>
            <UploadSVG />
            <Button terciary small>
              {t("upload.buttonUpload")}
            </Button>
            <input
              type="file"
              accept=".csv, .xlsm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: "none" }}
              id="uploadArchive"
              onChange={(e) =>
                uploadDataArchive(
                  e,
                  t,
                  setDataOfService,
                  dataOfService,
                  setComplete,
                  setUploadedExcelError,
                  setModalIsOpen,
                  dataOfUser
                )
              }
            />
            <span>{t("upload.descriptionUpload")}</span>
            <MPUploadDropSpace
              onClick={openApiFile}
              onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                uploadDataArchive(
                  e,
                  t,
                  setDataOfService,
                  dataOfService,
                  setComplete,
                  setUploadedExcelError,
                  setModalIsOpen,
                  dataOfUser
                );
              }}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            ></MPUploadDropSpace>
          </UploadContent>
          {/* <Button
            primary
            large
            disabled
            id={"sendRecetorsButtorn"}
            className="buttonUpload"
          >
            {t("packet.nextButton")}
          </Button> */}
        </MPUploadContainer>
      </BoxGeneral>
      {modalIsOpen && (
        <MainModal
          isOpen={true}
          closeModal={closeModal}
          isClose={modalIsOpen}
          background="#F8FAFD"
          width="auto"
          height="auto"
          borderRadius="8px"
          fillColor="#262A2E"
        >
          <ErrorSendXls
            paddingModal="30px 25px"
            uploadedExcelError={uploadedExcelError}
            dataOfService={dataOfService}
          />
        </MainModal>
      )}
    </main>
  );
}
