import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";
import {
  collection,
  query,
  where,
  doc,
  setDoc,
  getDocs,
  arrayUnion,
} from "firebase/firestore";

export const handleLoginWithEmail = (data, navigate, db, setCurrentUser, setLoading) => {
  const auth = getAuth();
  signInWithEmailAndPassword(auth, data.email, data.password)
    .then(async (userCredential) => {
      const user = userCredential.user
        const usersRef = collection(db, "users");
      if (user && user.emailVerified) {
        const recoverQuery = query(usersRef, where("email", "==", user.email));
        const recoverUser = await getDocs(recoverQuery);
        if (!recoverUser.empty) {
          const thisUserRef = doc(db, "users", user.uid);
          await setDoc(
            thisUserRef,
            {
              metadata: {
                createdAt: parseInt(user.metadata.createdAt),
                listOfLogins: arrayUnion(parseInt(user.metadata.lastLoginAt)),
              },
            },
            { merge: true }
          ).then(() => {
            setLoading(false)
            navigate("/home", { replace: true });
            setCurrentUser(auth.currentUser);
          });
        } else {
          signOut(auth).then(() => {
            setLoading(false)
            navigate("/register", { replace: true })
          });
          setCurrentUser(null);
        }
      } else {
        navigate("/email-verify");
      }
    })
    .catch((error) => {
      setLoading(false)
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
    });
};
