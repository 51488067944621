import { getAuth, sendPasswordResetEmail  } from 'firebase/auth'

export const handleSubmitRecoverPassword = async (email, setSendEmail, navigate) => {
    const auth = await getAuth();
    return await sendPasswordResetEmail(auth, email)
    .then(() => {
        console.log('Link Sended to Email RecoverPassword')
        setSendEmail(email)
        navigate("/recover-password-2", {state: {email_recover: email}, replace: true })
    })
    .catch(err => {
        console.error({code: err.code, message: err.message});
        setSendEmail(false)
    })
}