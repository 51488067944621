import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Wrapper } from "./../../styles/generalStyles";
import {
  InputContainer,
  FormContainer,
  InputSelect,
} from "./../../styles/inputGeneral";
import { Button } from "./../../styles/buttonGeneral";
import { Link, useNavigate } from "react-router-dom";
import {
  LoginAndRegisterContainer,
  LoginAndRegisterDesktop,
} from "./styles/sLoginAndRegister";
import { Text, ErrorText } from "./../../styles/textGeneral";
import logo from "./../../images/logos/image-24.png";
import background from "./../../images/image-14.webp";
import MainSpinner from "../../components/spinner/MainSpinner";

//Import data of location
import { departments } from "../../data/departments";
import { provinces } from "../../data/provinces";
import { districts } from "../../data/districts";
import { ubigeo } from "../../data/ubigeo";

//Import firebase utils
import { useFirestore } from "reactfire";

//Import ALGORITHMS
import { capitalize } from "../../algorithms/general/capitalize";
import { handleRegisterWithEmail } from "./algorithms/handleRegisterWithEmail";

//Validation Schema
import {
  nameFValidator,
  emailFValidator,
  passwordFValidator,
  phoneFValidator,
  DNIFValidator,
  bussinesNameFValidator,
  RUCFValidator,
  addressFValidator,
} from "./objects/formValidators";

export default function MainRegister() {
  const [t] = useTranslation("main");
  const db = useFirestore();
  const navigate = useNavigate();
  const { currentUser } = useContext(AppContext);
  const [isEntrepreneur, setIsEntrepreneur] = useState(false);
  const [department, setDepartment] = useState(14); //Lima por defecto
  const [province, setProvince] = useState(127); //Lima por defecto
  const [district, setDistrict] = useState("LIMA"); //Lima por defecto
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setLoading(true);
    if (isEntrepreneur) {
      const ubigeoData = ubigeo.find(
        (u) =>
          u.desc_prov_inei === provinces[province].province &&
          u.desc_ubigeo_inei === district
      ); // Recuperacion de ubigeo inei
      data.ubigeo = ubigeoData;
      await handleRegisterWithEmail(db, navigate, data, setLoading);
    } else {
      await handleRegisterWithEmail(db, navigate, data, setLoading);
    }
  };

  useEffect(() => {
    currentUser && navigate("/home");
    return () => {
      setLoading(false);
    };
  }, [loading]);

  if (loading) {
    return <MainSpinner />;
  }

  return (
    <main>
      <LoginAndRegisterDesktop>
        <div className="coverPageLoginAndRegisterContent">
          <img
            className="loginAndRegisterBackgroundImage"
            src={background}
            alt="Servicio Puntual"
          />
          <div>
            <p>Empecemos</p>
            <img src={logo} alt="Logo principal de Servicio Puntual" />
          </div>
        </div>
        <div></div>
        <Wrapper loginAndRegister>
          <LoginAndRegisterContainer>
            <h1>{t("signInAndUp.registerHere")}</h1>
            <div>
              <h4>{t("signInAndUp.personalData")}</h4>
              <FormContainer onSubmit={handleSubmit(onSubmit)}>
                <div className="inputContainerDuplo">
                  <InputContainer>
                    <label htmlFor={"name"}>{t("signInAndUp.name")}</label>
                    <input
                      type={"text"}
                      id={"name"}
                      {...register("name", nameFValidator)}
                    />
                    {errors.name && (
                      <ErrorText>{errors.name.message}</ErrorText>
                    )}
                  </InputContainer>
                  <InputContainer>
                    <label htmlFor={"DNI"}>{t("signInAndUp.documentId")}</label>
                    <input
                      type={"number"}
                      id={"DNI"}
                      {...register("DNI", DNIFValidator)}
                    />
                    {errors.DNI && <ErrorText>{errors.DNI.message}</ErrorText>}
                  </InputContainer>
                </div>
                <div className="inputContainerDuplo">
                  <InputContainer>
                    <label htmlFor={"personalEmail"}>
                      {t("signInAndUp.email")}
                    </label>
                    <input
                      type={"email"}
                      id={"personalEmail"}
                      {...register("personalEmail", emailFValidator)}
                    />
                    {errors.personalEmail && (
                      <ErrorText>{errors.personalEmail.message}</ErrorText>
                    )}
                  </InputContainer>
                  <InputContainer>
                    <label htmlFor={"personalPhone"}>
                      {t("signInAndUp.phone")}
                    </label>
                    <input
                      type={"number"}
                      id={"personalPhone"}
                      {...register("personalPhone", phoneFValidator)}
                    />
                    {errors.personalPhone && (
                      <ErrorText>{errors.personalPhone.message}</ErrorText>
                    )}
                  </InputContainer>
                </div>
                <div className="inputContainerDuplo">
                  <InputContainer>
                    <label htmlFor={"password"}>
                      {t("signInAndUp.password")}
                    </label>
                    <input
                      type={"password"}
                      id={"password"}
                      {...register("password", passwordFValidator)}
                    />
                    {errors.password && (
                      <ErrorText>{errors.password.message}</ErrorText>
                    )}
                  </InputContainer>
                </div>
                <label className="inputType inputCheckboxContent">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setIsEntrepreneur(e.target.checked);
                    }}
                  />
                  <Text>{t("signInAndUp.areYouAnEntrepreneur")}</Text>
                </label>
                {isEntrepreneur && (
                  <div>
                    <h4>{t("signInAndUp.companyData")}</h4>
                    <div className="inputContainerDuplo">
                      <InputContainer>
                        <label htmlFor={"bussinesName"}>
                          {t("signInAndUp.companyName")}
                        </label>
                        <input
                          type={"text"}
                          id={"bussinesName"}
                          {...register("bussinesName", bussinesNameFValidator)}
                        />
                        {errors.bussinesName && (
                          <ErrorText>{errors.bussinesName.message}</ErrorText>
                        )}
                      </InputContainer>
                      <InputContainer>
                        <label htmlFor={"RUC"}>
                          {t("signInAndUp.companyId")}
                        </label>
                        <input
                          type={"number"}
                          id={"RUC"}
                          {...register("RUC", RUCFValidator)}
                        />
                        {errors.RUC && (
                          <ErrorText>{errors.RUC.message}</ErrorText>
                        )}
                      </InputContainer>
                    </div>
                    <div className="inputContainerDuplo">
                      <InputContainer>
                        <label htmlFor={"bussinesPhone"}>
                          {t("signInAndUp.phone")}
                        </label>
                        <input
                          type={"number"}
                          id={"bussinesPhone"}
                          {...register("bussinesPhone", phoneFValidator)}
                        />
                        {errors.bussinesPhone && (
                          <ErrorText>{errors.bussinesPhone.message}</ErrorText>
                        )}
                      </InputContainer>
                      <InputContainer>
                        <label htmlFor={"bussinesEmail"}>
                          {t("signInAndUp.corporativeEmail")}
                        </label>
                        <input
                          type={"email"}
                          id={"bussinesEmail"}
                          {...register("bussinesEmail", emailFValidator)}
                        />
                        {errors.bussinesEmail && (
                          <ErrorText>{errors.bussinesEmail.message}</ErrorText>
                        )}
                      </InputContainer>
                    </div>
                    <div className="inputContainerDuplo">
                      <InputContainer>
                        <label htmlFor={"address"}>
                          {t("signInAndUp.address")}
                        </label>
                        <input
                          type={"text"}
                          id={"address"}
                          {...register("address", addressFValidator)}
                        />
                        {errors.address && (
                          <ErrorText>{errors.address.message}</ErrorText>
                        )}
                      </InputContainer>
                      <InputSelect>
                        <label>{t("signInAndUp.department")}</label>
                        <select
                          value={department}
                          onChange={(e) => {
                            let filterPD = provinces.filter(
                              (prov) => prov.deptId === e.target.selectedIndex
                            )[0].provinceIndex; //Filtro de la 1era provincia
                            setDepartment(e.target.selectedIndex);
                            setProvince(filterPD);
                            setDistrict(
                              districts.filter(
                                (dist) => dist.provId === filterPD
                              )[0].district
                            ); //Filtro del 1er distrito de la provincia
                          }}
                        >
                          {departments.map((dep, index) => {
                            //Iterador de departamentos
                            return (
                              <option value={index} key={dep} id={index}>
                                {capitalize(dep)}
                              </option>
                            );
                          })}
                        </select>
                      </InputSelect>
                    </div>
                    <div className="inputContainerDuplo">
                      <InputSelect>
                        <label>{t("signInAndUp.province")}</label>
                        <select
                          value={province}
                          onChange={(e) => {
                            setProvince(
                              parseInt(
                                e.target[e.target.selectedIndex].accessKey
                              )
                            );
                            setDistrict(
                              districts.filter(
                                (dist) =>
                                  dist.provId ===
                                  parseInt(
                                    e.target[e.target.selectedIndex].accessKey
                                  )
                              )[0].district
                            ); // Filtro del 1er distrito de una provincia
                          }}
                        >
                          {provinces
                            .filter((prov) => prov.deptId === department)
                            .map((prov) => (
                              <option
                                value={prov.provinceIndex}
                                key={`prov_${prov.province}`}
                                accessKey={prov.provinceIndex}
                              >
                                {capitalize(prov.province)}
                              </option>
                            ))}
                        </select>
                      </InputSelect>
                      <InputSelect>
                        <label>{t("signInAndUp.district")}</label>
                        <select
                          value={district}
                          onChange={(e) =>
                            setDistrict(
                              e.target[e.target.selectedIndex].accessKey
                            )
                          }
                        >
                          {districts
                            .filter((dist) => dist.provId === province)
                            .map((dist) => (
                              <option
                                value={dist.district}
                                key={`dist_${dist.district}`}
                                accessKey={dist.district}
                              >
                                {capitalize(dist.district)}
                              </option>
                            ))}
                        </select>
                      </InputSelect>
                    </div>
                  </div>
                )}
                <Text className="textCopyright">
                  {`${t("signInAndUp.aceptOur")} `}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    to="/terms"
                  >{`${t("signInAndUp.termsAndConditions")}`}</Link>
                  {` ${t("signInAndUp.andOur")} `}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    to={"/terms"}
                  >
                    {t("signInAndUp.privacy")}
                  </Link>
                </Text>
                <Button primary large type={"submit"}>
                  {t("signInAndUp.createAccount")}
                </Button>
              </FormContainer>
            </div>
          </LoginAndRegisterContainer>
        </Wrapper>
      </LoginAndRegisterDesktop>
    </main>
  );
}
