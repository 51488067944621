import {
  getAuth,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { capitalize } from "../../../algorithms/general/capitalize";

export const handleRegisterWithEmail = (db, navigate, data, setLoading) => {
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, data.personalEmail, data.password)
    .then(async (result) => {
      const user = result.user;
      if (user) {
        await sendData(db, data, user); //Envío de datos a la db de firestore
        await updateProfile(auth.currentUser, {
          displayName: capitalize(data.name), //Cambio de displayName
        });

        let actionCodeSettings = { // Configuración de urls de redirección de correo
          url: (process.env.NODE_ENV !== "production" ? 
            'http://localhost:3000/login/?email=' : 
            'https://serviciopuntual.com.pe/login/?email=') + auth.currentUser.email,
          // dynamicLinkDomain: 'https://serviciopuntual.com.pe/login'
        }

        await sendEmailVerification(auth.currentUser, actionCodeSettings) //Envio de email verificador de correo
          .then(() => {
            signOut(auth).then(() => {
              navigate("/email-verify", { replace: true, state: {email: user.email}})
              setLoading(false)
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })
    .catch((error) => {
      setLoading(false)
      console.log(error.code);
      console.log(error.message);
    });
};

const sendData = async (db, data, user) => {
  const thisUserRef = doc(db, "users", user.uid);
  const {
    DNI,
    RUC,
    address,
    bussinesEmail,
    bussinesName,
    bussinesPhone,
    name,
    personalPhone,
    ubigeo,
  } = data;
  try {
    await setDoc(
      thisUserRef,
      {
        name: {
          prevName: name ? name : null,
          firstName: null,
          lastName: null,
        },
        email: user.email,
        uid: user.uid,
        DNI: DNI ? DNI : null,
        personalPhone: personalPhone ? personalPhone : null,
        RUC: RUC ? RUC : null,
        address: address ? address : null,
        bussinesEmail: bussinesEmail ? bussinesEmail : null,
        bussinesName: bussinesName ? bussinesName : null,
        bussinesPhone: bussinesPhone ? bussinesPhone : null,
        department:
          ubigeo && ubigeo.desc_dep_inei ? ubigeo.desc_dep_inei : null,
        ubigeo: ubigeo ? ubigeo : null,
        typeOfUser: "B2C"
      },
      { merge: true }
    );
  } catch {
    sendData(db, data, user); //Reenvío en caso de error
  }
};
