import styled from "styled-components";

export const SolutionCardContainer = styled.button`
  position: relative;
  background: #d3e8f0;
  padding: 10px;
  margin: 10px 0 0 0;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 4px;
  ${(props) => props.className === "active" && "background: #A0C2d1"};
  border: 1px solid transparent;

  &:active,
  &:focus,
  &:focus-visible {
    border: 1px solid ${(props) => props.theme.brandClassicBlueColor};
  }
  
  .solutionCardContent {
    svg {
      fill: ${(props) => props.theme.brandCeruleanColor};
    }

    h3,
    p {
      text-align: left;
    }

    h3 {
      font-size: 1em;
      font-weight: ${(props) => props.theme.weight.semiBold};
    }

    p {
      font-size: 0.938em;
      margin: 10px 40px 20px 0;
      cursor: pointer;
    }

    .imageSolutionContent {
      ${(props) =>
        props.className === "active"
          ? `background: #e8eef1;
          fill: #1a7399;`
          : `background: ${props.theme.backgroundColor};`}
      width: 40px;
      height: 40px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .buttonSolutionContent {
      text-transform: uppercase;
      font-weight: ${(props) => props.theme.weight.semiBold};
      ${(props) =>
        props.className === "active"
          ? `background: #e8eef1;
            color: #1a7399;
          `
          : `background: ${props.theme.brandWhiteColor};
            color: ${(props) => props.theme.brandCeruleanColor};
          `}
      font-size: 0.878em;
      text-align: center;
      padding: 4px 0;
    }
  }

  &:hover {
    background: #b2cbd6;

    .imageSolutionContent,
    .buttonSolutionContent {
      background: #e8eef1;
      color: #1a7399;
      fill: #1a7399;
    }
  }

  @media (min-width: 768px) {
    padding: 20px;
    margin: 15px 0 0 0;

    .solutionCardContent {
      p {
        margin: 10px 80px 20px 0;
      }

      .imageSolutionContent {
        top: 20px;
        right: 20px;
      }
    }
  }
`;
