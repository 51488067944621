import * as XLSX from "xlsx";
import { ubigeo } from "../../../data/ubigeo";
import { getAllRatePrice } from '../algorithms/getRatePrice';

export const uploadDataArchive = (
  e,
  t,
  setDataOfService,
  dataOfService,
  setComplete,
  setUploadedExcelError,
  setModalIsOpen,
  dataOfUser
) => {
  let otherFile = false;
  let ordenatedData = [];
  let excelDataErrors = [];
  let empty = false;
  let typeServiceError = [];
  let file = e.target.files
    ? e.target.files[0]
    : e.dataTransfer.items[0].getAsFile();
  if (file.name.endsWith(".xlsm")) {
    let reader = new FileReader(file);
    reader.onload = async (e) => {
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: "array" });
      let worksheet = workbook.Sheets[workbook.SheetNames[1]];
      let sheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      if (
        sheet[0][0] !==
        "1e8aea93f07f5d7c040fb18548164388670f8fe2e6df2fff037d709bdc30b5a9ab20b444c41b5bc208dc903c5584cf94fbcf55b4ea893985659d19f3b108f8ac"
      ) {
        otherFile = true;
      } else {
        sheet.splice(0, 2);
        for (let i = 0; i < sheet.length; i++) {
          if (i === 0 && sheet[i].length === 0) {
            empty = true;
          }
          if (sheet[i].length === 0) {
            break;
          } else {
            let localColumns = [];
            let localRow = [];
            for (let j = 0; j < 18; j++) {
              if (!sheet[i][j] && j !== 0 && j !== 3 && j !== 4) {
                localRow[0] = i + 3;
                localColumns.push(j + 1);
              }
              if (j === 8) {
                if (dataOfService.serviceType === "province") {
                  typeServiceError.push(true);
                } else if (
                  (dataOfService.serviceType === "next-day" ||
                    dataOfService.serviceType === "same-day") &&
                  sheet[i][j] &&
                  (sheet[i][j].replace("_", " ").trim().toUpperCase() ===
                    "LIMA" ||
                    sheet[i][j].replace("_", " ").trim().toUpperCase() ===
                      "CALLAO")
                ) {
                  typeServiceError.push(true);
                } else {
                  typeServiceError.push(false);
                }
              }
            }
            if (localColumns.length > 0 && localRow.length > 0) {
              excelDataErrors.push({
                row: localRow,
                column: localColumns,
              });
            }
            if (excelDataErrors.length === 0) {
              ordenatedData.push({
                ecommerceName: sheet[i][0]?.toString().trim().toUpperCase() ?? null,
                name: sheet[i][1]?.toString().trim().toUpperCase() ?? null,
                phone: sheet[i][2]?.toString().trim() ?? null,
                email: sheet[i][3]?.toString().trim() ?? null,
                documentId: sheet[i][4]?.toString().trim() ?? null,
                address: sheet[i][5]?.toString().trim().toUpperCase() ?? null,
                reference: sheet[i][6]?.toString().trim().toUpperCase() ?? null,
                ubigeo: ubigeo.find(
                  (u) =>
                    u.desc_prov_inei ===
                      sheet[i][8]?.replace("_", " ").trim().toUpperCase() &&
                    u.desc_ubigeo_inei ===
                      sheet[i][9]?.replace("_", " ").trim().toUpperCase()
                ),
                packetDescription: sheet[i][10]?.trim().toUpperCase(),
                height: sheet[i][11]?.toString() ?? null,
                width: sheet[i][12]?.toString() ?? null,
                large: sheet[i][13]?.toString() ?? null,
                weight: sheet[i][14]?.toString() ?? null,
                fragile: sheet[i][15] === t("table.fragile") ? true : false,
                numberOfPackets: sheet[i][16]?.toString() ?? null,
                value: sheet[i][17]?.toString() ?? null,
              });
            }
          }
        }
      }
      if (
        excelDataErrors.length === 0 &&
        !empty &&
        !otherFile &&
        typeServiceError.filter((arr) => arr === false).length === 0
      ) {
        const newOrdenatedData = (dataOfUser.typeOfUser === "B2C" && dataOfService.serviceType !== "province")  ? 
          getAllRatePrice(ordenatedData, dataOfService) : ordenatedData

        const totalPrice = (dataOfUser.typeOfUser === "B2C" && dataOfService.serviceType !== "province") ? 
        newOrdenatedData.map(item => item.ratePrice).reduce((prevPrice, currentPrice) => prevPrice + currentPrice) : null
        setDataOfService({
          packetAndReceptorData: newOrdenatedData,
          amount: ordenatedData.length,
          totalPrice
        });
        setModalIsOpen(false);
        setComplete(true);
      } else {
        setModalIsOpen(true);
        setComplete(false);
      }
      setUploadedExcelError({
        typeServiceError,
        excelDataErrors,
        empty,
        otherFile,
      });
    };

    reader.readAsArrayBuffer(file);
  } else {
    otherFile = true;
  }

  setUploadedExcelError({
    typeServiceError,
    excelDataErrors,
    empty,
    otherFile,
  });
  if (otherFile) {
    setModalIsOpen(true);
    setComplete(false);
  }

  document.getElementById("uploadArchive").value = null;
};
