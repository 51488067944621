import React from 'react'
import { Wrapper } from "./../../styles/generalStyles";
import { Title2, Text } from "./../../styles/textGeneral";

export default function MainAssistance() {
  return (
    <main>
      <Wrapper morePages>
        <Title2 morePages>Contacta con un asistente</Title2>
        <Text>Asistente por Whatsapp: <span style={{fontWeight: 'bold'}}>(+51) 969 711 789</span></Text>
      </Wrapper>
    </main>
  )
}
